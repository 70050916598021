@use '../../../base/vars'as *;
@use 'sass:math';

.voice {
    background: $gray_lv2;
    padding-top: rem(70);
    margin-top: rem(80);

    @include media(sm) {
        margin-top: rem(120);
    }

    .voice_title {
        position: relative;
        font-size: rem(14);
        font-weight: 700;
        width: 100%;
        max-width: rem(294);
        min-height: rem(55);
        margin: 0 auto;
        background: $red_lv1;
        color: $white;
        border-radius: rem(10);
        text-align: center;
        line-height: calc(24/14);
        padding: rem(7) 0;

        @include media(sm) {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: vw(18);
            max-width: vw(800);
            min-height: vw(55);
            border-radius: vw(10);
            padding: 0;
        }

        @include media(xxl) {
            font-size: rem(18);
            max-width: rem(800);
            min-height: rem(55);
            border-radius: rem(10);
        }

        &::before,
        &::after {
            display: block;
            content: "";
            position: absolute;
            top: 50%;
            height: rem(1);
            background: $red_lv1;
            width: rem(20);

            @include media(sm) {
                width: vw(200);
            }

            @include media(xxl) {
                width: rem(200);
            }
        }

        &::before {
            left: 0;
            transform: translate(-100%, -50%);
        }

        &::after {
            right: 0;
            transform: translate(100%, -50%);
        }
    }

    .voice_splide {
        padding: rem(65) rem(20) rem(90);
    }
}


.voiceSplide {
    min-height: rem(296);

    .splide__slide {
        background: $white;
        border-radius: rem(10);
    }

    .splide__pagination {
        bottom: rem(-24);
        transform: translateY(50%);

        @include media(sm) {
            bottom: rem(-40);
        }
    }
}
