@use 'vars'as *;

.wrapper{
  width: 100%;
  max-width: rem(1240);
  padding: 0 rem(20);
  margin: 0 auto;
}

.container{
  width: 100%;
  max-width: rem(1000);
  margin: 0 auto;
}

.main{
  padding-top: rem(70);

  @include media(sm){
    padding-top: rem(120);
  }
}
