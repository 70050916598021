@use '../../../base/vars'as *;
@use 'sass:math';

.insta {
    background: $white;
    padding: rem(70) 0;
    @include mx-full;
    @include px-full;

    @include media(sm) {
        padding: rem(120) 0;
        @include mx-full;
        @include px-full;
    }

    .insta_inner {}

    .insta_list {
        display: grid;
        gap: rem(5);
        grid-template-columns: repeat(2, 1fr);
        margin-top: rem(20);

        @include media(sm) {
            margin-top: rem(60);
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .insta_link {
        position: relative;
        aspect-ratio: calc(330/330);
        overflow: hidden;

        img,
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: auto;
            max-width: initial;
            height: 100%;
        }
    }

    .insta_inner_button {
        max-width: rem(500);
        margin: 0 auto;
        margin-top: rem(30);

        @include media(sm) {
            margin-top: rem(60);
        }
    }
}
