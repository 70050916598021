@use '../../base/vars'as *;
@use 'sass:math';

.adult{

    .adult_course{
        padding-bottom: rem(80);

        @include media(sm){
            padding-bottom: rem(120);
        }
    }
}
