@use '../../base/vars'as *;
@use 'sass:math';

.multilingual{
    .anchor_box{
        background-color: $red;
        padding: rem(30) 0;

        @include media(sm){
            padding: rem(60) 0;
        }
    }

    .anchor_list{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: rem(16);

        @include media(sm){
            grid-template-columns: repeat(3, 1fr);
            gap: rem(30);
        }

        .button_lv1__text{
            font-weight: bold;
        }
    }

    .lang_content{
        padding-top: rem(80);

        @include media(sm){
            padding-top: rem(120);
        }
    }

    .lang_inner{
        padding-top: rem(30);

        @include media(sm){
            display: flex;
            align-items: start;
            gap: rem(60);
            padding-top: rem(60);
        }
    }

    .lang_text{
        width: 100%;

        .catch{
            font-weight: bold;
            font-size: rem(26);
            line-height: 1.5;
            letter-spacing: 0.03em;

            @include media(sm){
                font-size: rem(30);
            }

            .red{
                color: $red;
            }

            >span {
                display: inline-block;
            }
        }

        .text{
            font-size: rem(16);
            padding-top: rem(30);
            padding-left: rem(10);

            @include media(sm){
                padding-left: 0;
            }
        }

        .speak_content{
            padding-top: rem(30);
        }

        .speak_inner{
            padding: rem(14) rem(26);
            background-color: $gray_lv2;
            border-radius: rem(10);
        }

        .speak_text{
            position: relative;
            display: block;
            font-weight: bold;
            font-size: rem(16);
            padding-left: rem(24);

            &::before{
                content: '';
                position: absolute;
                top: .8em;
                left: 0;
                width: rem(16);
                height: rem(18);
                background-image: url('../svg/icons/check.svg');
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    .lang_img{
        width: 100%;
        padding-top: rem(30);

        @include media(sm){
            padding-top: 0;
            max-width: rem(440);
        }
    }

    .price_button{
        width: 100%;
        margin: 0 auto;
        padding-top: rem(30);

        @include media(sm){
            padding-top: rem(60);
            max-width: rem(500);
        }
    }
}
