@use '../../../base/vars'as *;

.header {
    background: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: rem(70);
    min-height: rem(70);
    padding: 0 rem(20);
    z-index: $header;

    @include media(sm) {
        padding: 0 vw(100);
        max-height: rem(120);
        min-height: rem(120);
    }

    @include media(xxl) {
        padding: 0 rem(100);
    }

    .header_logo {
        position: relative;
        width: 100%;
        max-width: rem(168);
        aspect-ratio: calc(168/48);

        @include media(sm) {
            aspect-ratio: calc(281/74);
            max-width: vw(281);
        }

        @include media(xxl) {
            max-width: rem(281);
        }
    }

    .header_content {
        display: none;

        @include media(md) {
            display: grid;
            align-items: center;
            grid-template-columns: 1fr vw(211);
            gap: 1em vw(50);
        }

        @include media(xxl) {
            grid-template-columns: 1fr rem(211);
            gap: 1em rem(50);
        }
    }

    .header_navi {}

    .header_navi_list {
        display: flex;
        gap: 1em rem(30);

        @include media(md) {
            gap: 1em vw(30);
        }

        @include media(xxl) {
            gap: 1em rem(30);
        }
    }

    .header_navi_item {
        position: relative;
        display: flex;
        align-items: center;
        font-weight: 700;

        @include media(md) {
            font-size: vw(18);
        }

        @include media(xxl) {
            font-size: rem(18);
        }

        &::before {
            display: block;
            content: "";
            width: 0;
            height: rem(1);
            position: absolute;
            bottom: rem(-2);
            left: 0;
            background: $blue;
            transition: width .3s ease-in-out;
        }

        &:hover,
        &.current {
            &::before {
                width: 100%;
            }
        }

        &:hover {
            .header_navi_dropdown {
                margin-top: rem(10);
                padding-top: rem(8);
                background: $white;
            }

            .header_navi_dropdown_list {
                line-height: 1.5;
                visibility: visible;

                @include media(sm) {
                    padding: vw(15) vw(10);
                }

                @include media(xxl) {
                    padding: rem(15);
                }
            }
        }

        .material-symbols-outlined {
            font-size: 2em;
        }
    }

    .header_navi_dropdown {
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: rem(200);
        transform: translate(-50%, 100%);
        border-radius: rem(4);
        filter: drop-shadow(0 0 rem(6) rgba($black, .3));
        transition:
            visibility .2s ease-in .2s,
            margin .2s ease-in,
            padding .2s ease-in;

        @include media(sm) {
            margin-top: 0;
            padding-top: 0;
            width: vw(200);
            padding-left: vw(15);
            padding-right: vw(15);
        }

        @include media(xxl) {
            padding-left: rem(15);
            padding-right: rem(15);
            width: rem(200);
        }
    }

    .header_navi_dropdown_list {
        visibility: hidden;
        line-height: 0;
        transition:
            visibility .2s ease-in,
            margin .2s ease-in,
            padding .2s ease-in,
            line-height .2s ease-in;

        &:not(:first-of-type) {
            padding-top: 0;
            margin-top: 0;
            border-top: rem(1) solid $blue;
        }

        @include media(sm) {
            padding-left: vw(15);
            padding-right: vw(15);
            font-size: vw(16);
        }

        @include media(xxl) {
            padding-left: rem(15);
            padding-right: rem(15);
            font-size: rem(16);
        }
    }

    .header_contact {}

    .header_contact_link {
        position: relative;
        width: 100%;
        aspect-ratio: calc(211/69);
    }
}
