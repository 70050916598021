@use '../../../base/vars'as *;
@use 'sass:math';

.pricelist{
    .price_table{
        width: 100%;
        margin-top: rem(40);
        font-weight: bold;
        font-size: rem(18);
        text-align: center;
        line-height: 1.5;
        border-collapse: separate;
        border-spacing: 0;
        border-radius: rem(10);

        .price_thead{
            th{
                color: $white;
                font-weight: 500;
                padding: rem(17) rem(10);
                background-color: $red;
                border-left: rem(1) solid $white;

                @include media(sm){
                    padding: rem(17);
                }

                &:first-of-type{
                    border-top-left-radius: rem(10);
                    border-left: 0;
                }

                &:last-of-type{
                    border-top-right-radius: rem(10);
                }
            }
        }

        .price_tbody{
            th{
                color: $white;
                vertical-align: middle;
                padding: rem(17) rem(10);
                background-color: $red;
                border-top: rem(1) solid $white;

                @include media(sm){
                    padding: rem(17);
                }
            }

            .brown{
                th{
                    font-weight: 500;
                    background-color: $red_lv4;
                    border-radius: 0;
                    border: none;
                    padding: rem(7) rem(17);
                }
            }

            td{
                vertical-align: middle;
                padding: rem(17) rem(10);
                border-left: rem(1) solid $gray_lv3;
                border-bottom: rem(1) solid $gray_lv3;

                &:last-of-type{
                    border-right: rem(1) solid $gray_lv3;
                }

                @include media(sm){
                    padding: rem(17);
                }
            }

            .annotation{
                td{
                    font-weight: normal;
                    font-size: rem(14);
                    text-align: left;
                    line-height: 2;
                    padding: rem(15) rem(9);

                    @include media(sm){
                        padding: rem(20) rem(100);
                    }
                }
            }

            .count{
                display: block;
                width: 100%;
                min-width: rem(74);
                line-height: 2;
                margin: 0 auto;
            }

            .price{
                display: block;
                width: 100%;
                max-width: rem(148);
                margin: 0 auto;
                text-align: right;
                white-space: nowrap;

                @include media(sm){
                    max-width: rem(80);
                }
            }

            .price_other{
                display: block;
                max-width: rem(126);
                margin: 0 auto;
                text-align: left;
            }

            .online{
                position: relative;
                display: inline-block;
                text-align: left;
                padding-left: rem(30);

                @include media(sm){
                    padding-left: rem(32);
                }

                &::before{
                    content: '';
                    position: absolute;
                    top: 0.8em;
                    left: 0;
                    transform: translateY(-50%);
                    width: rem(21);
                    height: rem(20);
                    background-image: url('../svg/icons/onlinePC_white.svg');
                    background-repeat: no-repeat;
                    background-size: contain;

                    @include media(sm){
                        background-image: url('../svg/icons/onlinePC_red.svg');
                    }
                }

                >span {
                    display: inline-block;
                }
            }
        }

        &.certification{
            margin-top: rem(10);

            @include media(sm){
                margin-top: rem(40);
            }

            .price{
                @include media(sm){
                    max-width: rem(104);
                }
            }
        }

        &.teacher{
            margin-top: rem(50);
        }

        // table utilities
        .even{
            background-color: $gray_lv2;
        }

        .font_regular{
            font-weight: 400 !important;
        }

        .round_bl{
            border-bottom-left-radius: rem(10) !important;
        }

        .round_br{
            border-bottom-right-radius: rem(10) !important;
        }

        .border_ln{
            border-left: none !important;
        }

        .border_rn{
            border-right: none !important;
        }

        .border_bn{
            border-bottom: none !important;
        }
    }
}
