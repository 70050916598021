@use '../../base/vars'as *;
@use 'sass:math';

.lecturer{

    .lecturer_header{
        padding-top: rem(80);

        @include media(sm){
            padding-top: rem(120);
        }
    }

    .prelusion_inner{
        padding-top: rem(60);

        @include media(sm){
            padding-top: rem(120);
        }
    }

    .prelusion_text{
        margin-top: rem(30);
        font-size: rem(16);
        font-weight: bold;

        @include media(sm){
            margin-top: rem(40);
            font-size: rem(18);
        }
    }

    .prelusion{
        margin-right: calc(50% - 50vw);
        margin-left: calc(50% - 50vw);
        padding-bottom: rem(60);
        padding-right: calc(50vw - 50%);
        padding-left: calc(50vw - 50%);
        background-color: $gray_lv2;

        @include media(sm){
            padding-bottom: rem(120);
        }

        .prelusion_lists{
            display: flex;
            flex-wrap: wrap;
            margin-top: rem(-40);
            margin-left: rem(-20);

            @include media(xs){
                margin-left: rem(-40);
            }

            @include media(md){
                margin-top: rem(-60);
                margin-left: rem(-50);
            }
        }

        .prelusion_lists_item{
            width: 50%;
            padding-top: rem(40);
            padding-left: rem(20);

            @include media(xs){
                width: 33.33333%;
                padding-left: rem(40);
            }

            @include media(sm){
                width: 25%;
            }

            @include media(md){
                padding-top: rem(60);
                padding-left: rem(50);
            }
        }

        .person_figure_img{
            position: relative;

            &,
            .default_img,
            .hover_img,
            picture,
            img{
                border-radius: rem(999);
            }

            .hover_normal {
                overflow: hidden;
                width: 100%;
                height: 100%;
            }

            .hover_normal img{
                transition: transform .2s linear;
            }

            .hover_normal:hover img{
                transform: scale(1.1);
            }

            .hover_action{
                position: absolute;
                top: 0;
                left: 0;
                // transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                transition: opacity .5s linear;
            }

            .hover_action img{
                object-fit: cover;
            }

            .hover_action:hover{
                opacity: 0;
            }
        }


        .person_figcaption{

            .name,
            .name_ja{
                display: block;
                text-align: center;
                line-height: 1.1;
            }

            .name{
                font-size: rem(20);
                font-weight: bold;
                margin-top: rem(10);

                @include media(sm){
                    font-size: rem(28);
                    margin-top: rem(20);
                }
            }

            .name_ja{
                font-size: rem(16);
                font-weight: 500;
                margin-top: rem(5);

                @include media(sm){
                    font-size: rem(18);
                    margin-top: rem(10);
                }
            }
        }
    }

    .modal_contents{
        border-radius: rem(10);
        background-color: $white;
        width: 100%;
        height: 90%;

        @include media(sm){
            height: 80%;
        }

        @include media(md){
            width: 80%;
        }
    }

    .modal_contents_inner{
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        border-radius: rem(10);

        @include media(sm){
            display: grid;
            grid-template-columns: rem(300) 1fr;
        }
    }

    .modal_panel .profile{
        color: $white;
        background-color: $red;
        padding: rem(40) rem(20);
        position: relative;

        @include media(sm){
            padding: rem(72) rem(20);
        }

        .profile_figure{
            max-width: rem(120);
            margin: 0 auto;
            position: absolute;
            top: rem(20);
            left: 50%;
            transform: translateX(-50%);

            @include media(sm){
                max-width: rem(200);
                position: sticky;
                bottom: auto;
                top: rem(72);
                left: 0;
                transform: translateX(0);
            }

            img{
                border-radius: rem(999);
            }
        }

        .profile_figcaption{

            .name,
            .name_ja{
                display: block;
                text-align: center;
                color: $red;

                @include media(sm){
                    color: $white;
                }
            }

            .name{
                font-size: rem(22);
                font-weight: bold;

                @include media(sm){
                    font-size: rem(30);
                }
            }

            .name_ja{
                font-size: rem(14);
                font-weight: 500;
                margin-top: rem(-4);

                @include media(sm){
                    font-size: rem(18);
                }
            }
        }
    }

    .modal_panel .comment{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: rem(150) rem(20) rem(50);

        @include media(sm){
            padding: rem(50) rem(20);
        }

        .comment_inner{
            max-width: rem(778);
            margin: 0 auto;
            flex: 1;
        }

        .sec_ja{
            font-weight: 500;
             padding-bottom: rem(50);
        }

        .sec_en{
            font-weight: 400;
            padding-top: rem(50);
        }

        .sec_ja + .sec_en{
            border-top: solid rem(1) $red;
        }

        .capabilities{
            margin-top: rem(40);
        }
    }

    .modal_close{
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: rem(999);
        border: solid rem(2) $red;
        position: absolute;
        top: rem(-15);
        right: rem(-15);
        width: rem(50);
        height: rem(50);
        width: 50px;
        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));

        img{
            width: rem(25);
            height: rem(25);
        }
    }

    .comment_others{

        .comment_others_item + .comment_others_item{
            margin-top: rem(30);

            @include media(sm){
                margin-top: rem(50);
            }
        }

        .comment_others_item img{
            width: auto !important;
            height: auto !important;
        }
    }
}

