@use 'vars'as *;


.pc_only {
    display: none;

    @include media(sm) {
        display: block;
    }
}

.sp_only {
    @include media(sm) {
        display: none;
    }
}

.hover {
    transition: opacity .3s ease-out;

    @include hover {
        opacity: .8;
    }
}

.inlineBlock{
    // display: inline-block;
}

// スクリーンリーダー
.sr_only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

// textサイズ
.text_large {
    font-size: 1.3em;
}

.text_small {
    font-size: .75em !important;
}


// アイコン
.icon_flag,
.icon_recommend,
.icon_online {
    display: inline-block;
    position: relative;
    color: transparent;
    font-size: rem(1);
    line-height: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: transparent;
}

.icon_flag {
    width: rem(21);
    aspect-ratio: calc(21/24);
    background-image: url("../svg/icons/flag.svg");
}

.icon_online {
    width: rem(87);
    aspect-ratio: calc(87/27);
    background-image: url("../svg/icons/online.svg");
}

.icon_recommend {
    width: rem(178);
    aspect-ratio: calc(178/59);
    background-image: url("../svg/icons/recommend.svg");
}

.he_mx-full {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
}


.text_red {
    color: $red;
}


.iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
