@use '../../../base/vars'as *;
@use 'sass:math';


.media_lv1{
    background-color: $gray_lv2;
    margin: 0 calc(50% - 50vw);
    padding: rem(50) rem(20);

    @include media(sm){
        padding: rem(80) rem(20);
    }

    .media_lv1_title{
        font-size: rem(30);
        font-weight: bold;
        letter-spacing: 0.03em;
        line-height: 1.5;
        text-align: center;
    }

    .media_lv1_contents{
        max-width: rem(1200);
        margin: rem(30) auto 0;

        @include media(sm){
            margin: rem(60) auto 0;

            @include media(sm){
                display: grid;
                grid-template-columns: rem(300) 1fr;
            }
        }
    }

    .media_lv1_profile{
        background-color: $red;
        height: rem(83);
        position: relative;
        border-radius: rem(10) rem(10) 0 0;

        @include media(sm){
            height: auto;
            border-radius: rem(10) 0 0 rem(10);
        }
    }

    .media_lv1_profile_figure{
        position: absolute;
        top: rem(20);
        left: 50%;
        transform: translateX(-50%);

        @include media(sm){
            position: static;
            top: auto;
            left: auto;
            transform: translateX(0);
            padding: rem(60) 0;
        }
    }

    .media_lv1_profile_figure_img{
        width: rem(113);
        margin: 0 auto;

        @include media(sm){
            width: rem(200);
        }

        img{
            border-radius: rem(999);
        }
    }

    .media_lv1_profile_figcaption{
        font-size: rem(18);
        font-weight: bold;
        color: $red;
        text-align: center;
        line-height: calc(24/18);

        @include media(sm){
            font-size: rem(30);
            color: $white;
            margin-top: rem(10);
        }
    }

    .media_lv1_box{
        background-color: $white;
        padding: rem(100) rem(20) rem(25);
        border-radius: 0 0 rem(10) rem(10);
        display: flex;
        justify-content: center;
        align-items: center;

        @include media(sm){
            padding: rem(60) rem(20);
            border-radius: 0 rem(10) rem(10) 0;
        }
    }

    .media_lv1_box_inner{
        flex: 1;
        max-width: rem(780);
    }

    .media_lv1_box_title{
        display: flex;
        align-items: center;
        gap: .5em;
        font-size: rem(26);
        font-weight: bold;
        letter-spacing: 0.03em;
        line-height: 1.4;
        padding-bottom: rem(16);
        border-bottom: solid rem(1) $red;
        color: $red;

        @include media(sm){
            font-size: rem(30);
            padding-bottom: rem(40);
        }
    }

    .media_lv1_box_text{
        margin-top: rem(20);
        font-size: rem(14);
        font-weight: 500;
        line-height: 2;

        @include media(sm){
            font-size: rem(16);
            margin-top: rem(40);
        }

        p+p {
            margin-top: rem(20);

            @include media(sm) {
                margin-top: rem(40);
            }
        }

        ul{
            padding-left: 1.5em;
            list-style-type: disc;
        }
    }
}
