@use '../../../base/vars'as *;
@use 'sass:math';

.sns {
    position: fixed;
    top: rem(423);
    right: rem(16);
    display: none;
    z-index: $sns;

    @include media(sm) {
        display: block;
    }

    .sns_list {
        display: flex;
        flex-direction: column;
        gap: rem(13);
    }

    .sns_item {}

    .sns_link {
        position: relative;
        width: rem(70);
        aspect-ratio: calc(70/70);
    }
}

.line {
    position: fixed;
    bottom: rem(20);
    right: rem(5);
    z-index: $sns;

    @include media(sm) {
        right: rem(16);
    }

    .sns_link {
        position: relative;
        width: 100%;
        max-width: rem(85);
        aspect-ratio: calc(85/107);

        @include media(sm) {
            max-width: rem(270);
            aspect-ratio: calc(270/88);
        }
    }
}
