@use '../../base/vars'as *;
@use 'sass:math';

.heading_lv1{

    .heading_lv1_Subtitle{
        display: block;
        font-size: rem(16);
        font-weight: bold;
        color: $red;

        @include media(sm){
            font-size: rem(18);
        }
    }

    .heading_lv1_title{
        display: block;
        font-size: rem(28);
        font-weight: bold;
        padding-bottom: rem(8);
        border-bottom: solid rem(1) $red;

        @include media(sm){
            font-size: rem(34);
            padding-bottom: rem(12);
        }
    }

    .text-red{
        color: $red;
    }
}
