@use 'vars'as *;

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  overflow-x: hidden;
  font-family: $font-noto;
  line-height: $line-height;
  color: $black;
  background-color: $white;
}

a {
  color: $black;
  text-decoration: none;
  display: block;
}

img{
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: block;
}

p{
  line-height: $line-height;
  font-size: rem(18);
}

input,
select,
button,
textarea {
  box-sizing: border-box;
}

input[type=text],
input[type=password],
input[type=tel],
input[type=email],
input[type=number],
input[type=submit],
input[type=button],
input[type=reset],
textarea,
button,
select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
}

input[type=submit],
input[type=button],
input[type=reset],
button,
label[for] {
  cursor: pointer;
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

ul,
ol {
  list-style: none;
}
