@use '../../../base/vars'as *;
@use 'sass:math';

.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $red_lv1;
    padding: rem(30);
    margin-top: rem(40);

    @include media(sm) {
        margin-top: 0;
    }

    .banner_img {
        position: relative;
        width: 100%;
        max-width: rem(316);
        aspect-ratio: calc(316/233);

        @include media(sm) {
            max-width: vw(800);
            aspect-ratio: calc(800/180);
        }

        @include media(xxl) {
            max-width: rem(800);
        }
    }
}
