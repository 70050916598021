@use '../../../base/vars'as *;
@use 'sass:math';

.footer {
    padding-top: rem(60);

    @include media(sm){
        padding-top: rem(120);
    }

  .footer_contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $red;
    padding: rem(40) 0;

    @include media(sm) {
      padding: rem(60) 0 rem(70);
    }
  }

  .footer_contact_text {
    color: $white;
    font-size: rem(18);
    font-weight: 700;
    font-family: $font-Rounded;

    @include media(sm) {
      font-size: rem(24);
    }
  }

  .footer_contact_button {
    margin-top: rem(20);
    max-width: rem(336);

    @include media(sm) {
      margin-top: rem(18);
      max-width: rem(500);
    }
  }

  .footer_contact_link {
    position: relative;
    width: 100%;
    aspect-ratio: calc(336/54);

    @include media(sm){
        aspect-ratio: calc(500/69);
    }
  }

  .footer_content {
    max-width: rem(1374);
    margin: 0 auto;
    padding: rem(40) rem(20) rem(50);

    @include media(sm) {
      padding: rem(55) rem(20) rem(78);
    }
  }

  .footer_content_logo {
    position: relative;
    width: 100%;
    max-width: rem(205);
    aspect-ratio: calc(281/74);

    @include media(sm) {
      max-width: rem(281);
    }
  }

  .footer_content_inner {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(30) rem(80);
    margin-top: rem(50);

    @include media(xl) {
      grid-template-columns: rem(1000) 1fr;
    }
  }

  .footer_school_info {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(40) rem(60);
    background: $gray_lv2;
    padding: rem(20) rem(10) rem(30);

    @include media(sm) {
      grid-template-columns: repeat(2, 1fr);
      padding: rem(30) rem(55) rem(34);
    }
  }

  .footer_school_info_item {}

  .footer_school_info_title {
    font-size: rem(16);
    font-weight: 500;
    padding-bottom: rem(10);
    border-bottom: rem(1) solid $blue;
  }

  .footer_school_info {}

  .footer_school_info_inner {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(2);
    margin-top: rem(10);
    padding-left: rem(14);
  }

  .footer_school_info_row {
    display: grid;
    grid-template-columns: rem(84) 1fr;
    gap: rem(2) rem(10);
    font-size: rem(14);
  }

  .footer_school_info_term {
    font-weight: 500;
    padding: rem(6) rem(12);
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: calc(28/14);
  }

  .footer_school_info_desc {
    display: flex;
    align-items: center;

    &.address {
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .postal_code {}

  .footer_school_info_address {
    font-style: normal;
  }

  .footer_nav {}

  .footer_nav_list {
    font-size: rem(14);
    font-weight: 500;
    display: grid;
    gap: rem(20) rem(30);
    grid-template-columns: rem(140) 1fr;
    grid-template-areas:
      "area1 area8"
      "area2 area9"
      "area3 area10"
      "area4 area11"
      "area5 area12"
      "area6 area13"
      "area7 area7"
  }

  .footer_nav_item {

    @for $i from 1 through 13 {
      &.area#{$i} {
        grid-area: area#{$i};
      }
    }
  }


  .footer_nav_link {}

  .footer_copyright {
    background: $red;
    display: flex;
    justify-content: center;
    color: $white;
    padding: rem(12) 0;

    >* {
      font-size: rem(10);
      font-weight: 700;
    }
  }
}
