@use '../../base/vars'as *;
@use 'sass:math';

.about{

    .about_lead{
        padding-top: rem(40);
        padding-bottom: rem(80);

        @include media(sm){
            padding-top: rem(120);
            padding-bottom: rem(120);
        }
    }

    .about_lead_title{
        font-size: rem(16);
        font-weight: bold;
        color: $red;

        @include media(sm){
            font-size: rem(18);
        }
    }

    .about_lead_box{
        margin-top: rem(20);

        @include media(sm){
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }

        p:last-child{
            margin-top: rem(40);
        }
    }

    .about_lead_box_text{
        font-size: rem(16);
        font-weight: bold;
        line-height: 2;

        @include media(sm){
            flex: 1;
            padding-right: rem(50);
        }
    }

    .about_lead_box_img{
        margin: rem(40) auto 0;
        width: 100%;
        max-width: rem(340);

        @include media(sm){
            margin: 0;
        }
    }

    .about_act{
        padding-top: rem(80);

        @include media(sm){
            padding-top: rem(120);
        }
    }
}
