@use '../../../base/vars'as *;
@use 'sass:math';

.dispatch {
    padding: rem(60) 0;
    background: $gray_lv2;
    @include mx-full;

    @include media(md) {
        padding: rem(120) 0;
    }

    .dispatch_inner {
        @include media(md) {
            margin: 0 rem(20);
        }
    }

    .dispatch_card {
        width: 100%;
        max-width: rem(600);
        background: url("../img/top/dispatch/dispatch_sp@2x.webp") no-repeat;
        background-size: 100% auto;
        margin: 0 auto;
        padding: rem(190) rem(20) 0;

        @include media(md) {
            padding: vw(190, 769) rem(20) 0;
        }

        @include media(md) {
            background: url("../img/top/dispatch/dispatch@2x.webp") no-repeat;
            background-size: percentage(math.div(1100, 1720)) auto;
            padding: rem(40) 0;
            margin: 0;
            max-width: 100%;
        }

        .dispatch_card_content {
            padding: rem(30) rem(25);
            background: $white;
            border-radius: rem(10);
            width: 100%;

            @include media(md) {
                padding: rem(50) rem(60);
                max-width: percentage(math.div(780, 1720));
                margin-left: auto;
            }
        }

        .dispatch_card_content_header {}

        .dispatch_card_title {
            display: flex;
            flex-direction: column;
            gap: rem(15);
            font-weight: 700;
        }

        .dispatch_card_title_main {
            font-size: rem(14);
            color: $red_lv1;

            @include media(md) {
                font-size: vw(18);
            }

            @include media(xxl) {
                font-size: rem(18);
            }
        }

        .dispatch_card_title_sub {
            font-size: rem(24);

            @include media(md) {
                font-size: vw(34);
            }

            @include media(xxl) {
                font-size: rem(34);
            }
        }

        .dispatch_card_content_body {
            margin-top: rem(14);
            padding-top: rem(20);
            border-top: rem(1) solid $red_lv1;

            @include media(md) {
                margin-top: rem(30);
                padding-top: rem(30);
            }
        }

        .dispatch_card_description {
            font-size: rem(16);
            font-weight: 500;

            // @include media(md) {
            //     font-size: vw(16);
            // }

            @include media(xxl) {
                font-size: rem(16);
            }
        }

        .dispatch_card_content_footer {
            margin-top: rem(30);

            @include media(md) {
                margin-top: rem(60);
            }
        }
    }
}
