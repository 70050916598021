@use '../../../base/vars'as *;
@use 'sass:math';

.kv {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(40) 0;
    grid-template-areas:
        "text"
        "splide"
        "button";
    margin-top: rem(40);

    @include media(sm) {
        margin-top: 0;
        grid-template-areas:
            "text splide"
            "button splide";
        grid-template-columns: vw(660) vw(1260);
    }

    @include media(xxl) {
        grid-template-columns: rem(660) rem(1260);
    }

    .kv_text {
        grid-area: text;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    .kv_text_img {
        position: relative;
        width: 100%;
        max-width: rem(316);
        aspect-ratio: calc(316/114);

        @include media(sm) {
            aspect-ratio: calc(532/162);
            max-width: percentage(math.div(532, 660));
        }

        @include media(xxl) {
            max-width: rem(532);
        }
    }

    .kv_button {
        grid-area: button;
    }

    .kv_button_link {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: rem(280);
        min-height: rem(58);
        background: $blue;
        padding: 0 rem(53);
        color: $white;
        font-size: rem(16);
        font-weight: 700;
        border-radius: rem(10);
        margin: 0 auto;

        @include media(sm) {
            font-size: vw(20);
            max-width: vw(370);
            min-height: vw(75);
            padding: 0 vw(82);
            font-size: vw(20);
            border-radius: vw(10);
        }

        @include media(xxl) {
            max-width: rem(370);
            min-height: rem(75);
            padding: 0 rem(82);
            font-size: rem(20);
            border-radius: rem(10);
        }

        .material-symbols-outlined {
            position: absolute;
            top: 50%;
            right: rem(17);
            transform: translateY(-50%);
            color: currentColor;
            font-weight: 700;
            font-size: 1.5em;

            @include media(sm) {
                right: vw(24);
            }

            @include media(xxl) {
                right: rem(24);
            }
        }
    }

    .kv_splide {
        grid-area: splide;
    }

    .kv_img {
        position: relative;
        width: 100%;
        aspect-ratio: calc(316/300);

        @include media(sm) {
            aspect-ratio: calc(800/600);
        }
    }
}
