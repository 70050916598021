@use '../../base/vars'as *;
@use 'sass:math';

.classroom {
    .anchor_box {
        background-color: $gray_lv2;
        padding: rem(50) 0;

        @include media(sm) {
            padding: rem(60) 0;
        }
    }

    .anchor_list {
        display: grid;
        gap: rem(40);

        @include media(sm) {
            grid-template-columns: repeat(2, 1fr);
            gap: rem(60);
        }

        .button_lv1 {
            padding: rem(10) rem(15);

            @include media(sm) {
                padding: rem(10) rem(25);
            }
        }
    }

    .anchor_item {
        .map {
            width: 100%;
            aspect-ratio: calc(336/220);
            line-height: 1;

            @include media(sm) {
                aspect-ratio: calc(470/220);
            }
        }

        .button {
            padding-top: rem(30);
        }

        .address {
            font-size: rem(16);
            font-weight: bold;
            padding-top: rem(20);
            padding-left: rem(10);

            @include media(sm) {
                font-size: rem(18);
                padding-left: rem(25);
            }
        }
    }

    .class_content {
        padding-top: rem(80);

        @include media(sm) {
            padding-top: rem(120);
        }
    }

    .class_catch {
        font-size: rem(26);
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 0.03em;
        padding-top: rem(30);

        @include media(sm) {
            font-size: rem(30);
            padding-top: rem(60);
        }

        .red {
            color: $red;
        }

        >span {
            display: inline-block;
        }
    }

    .class_text {
        font-size: rem(16);
        padding-top: rem(30);
    }

    .classImg_list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: rem(15);
        padding-top: rem(30);

        @include media(sm) {
            grid-template-columns: repeat(3, 1fr);
            gap: rem(30);
            padding-top: rem(60);
        }
    }

    .classImg_item {
        border-radius: rem(10);
    }

    .class_campaign {
        display: grid;
        gap: rem(20);
        margin-top: rem(40);
        padding: rem(15) rem(20);
        background-color: $gray_lv2;
        border-radius: rem(10);

        @include media(sm) {
            padding: rem(15) rem(60);
            margin-top: rem(60);
        }

        .campaign_title {
            font-family: $font-Tekton-Condense;
            font-weight: 700;
            font-size: rem(16);
            color: $red;
            letter-spacing: 0.05em;

            @include media(sm) {
                font-size: rem(18);
            }
        }

        .campaign_text {
            display: block;
            font-weight: bold;
            font-size: rem(16);

            @include media(sm) {
                padding-left: rem(20);
            }

            &.outside {
                color: $gray;
                padding-left: 0;
            }
        }
    }

    .class_inner {
        display: grid;
        gap: rem(30);
        padding-top: rem(40);

        @include media(sm) {
            grid-template-columns: repeat(2, 1fr);
            gap: rem(70);
            justify-content: space-between;
            padding-top: rem(60);
        }

        .class_request {
            @include media(sm) {
                padding-right: rem(25);
            }
        }

        .map {
            width: 100%;
            aspect-ratio: calc(336/223);
            line-height: 1;

            @include media(sm) {
                aspect-ratio: calc(440/500);
            }

            iframe {
                border-radius: rem(10);
            }
        }

        .request_button {
            padding-top: rem(30);

            @include media(sm) {
                padding-top: rem(40);
            }
        }

        .access_title {
            font-size: rem(18);
            font-weight: bold;
            color: $red;
            line-height: 1.5;
        }

        .class_accessItem {
            padding-top: rem(20);
            padding-bottom: rem(12);
            border-bottom: rem(1) solid $red;

            @include media(sm) {
                padding-top: rem(30);
            }

            &:first-of-type {
                padding-top: 0;
            }
        }

        .access_text {
            font-size: rem(16);
            padding-top: rem(5);
            padding-left: rem(20);

            .attention {
                font-size: rem(14);
            }
        }
    }
}
