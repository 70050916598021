@use "sass:math";

///*------------------------------------*//
//    Fonts
//*------------------------------------*//
$font-noto: 'Noto Sans JP',
sans-serif;
$font-Rounded: "M PLUS 2",
sans-serif;
$font-Tekton-Condense: "tekton-pro-condensed",
sans-serif;

$line-height: 2;



///*------------------------------------*//
//    Colors
//*------------------------------------*//
$white:#ffffff;

$black:#333333;

$red: #FF0600;
$red_lv1: #DA1725;
$red_lv2: #DA1725;
$red_lv3: #DF0510;
$red_lv4: #A2272C;

$blue: #1E3CF2;
$blue_lv2: #003CF2;
$light-blue: #1877F2;

$yellow: #FADF0D;

$gray: #939393;
$gray_lv2: #F7F5F5;
$gray_lv3: #CECECE;

$green: #00B900;



///*------------------------------------*//
//   z-index
//*------------------------------------*//
$bg: 1;
$main: 10;
$menu: 100;
$sns: 999;
$header: 1000;
$hamburger: 1001;
$modal: 10000;



///*------------------------------------*//
//     breakpoints
//*------------------------------------*//
$breakpoints: (xs: 'screen and (min-width: 600px)',
    sm: 'screen and (min-width: 769px)',
    md: 'screen and (min-width: 1000px)',
    lg: 'screen and (min-width: 1200px)',
    xl: 'screen and (min-width: 1366px)',
    xxl: 'screen and (min-width: 1920px)',
) !default;

$max-breakpoints: (xs: 'screen and (max-width: 599px)',
    sm: 'screen and (max-width: 768px)',
    md: 'screen and (max-width: 999px)',
    lg: 'screen and (max-width: 1199px)',
    xl: 'screen and (max-width: 1365px)',
    xxl: 'screen and (max-width: 1919px)',
) !default;



///*------------------------------------*//
//     Mixin
//*------------------------------------*//
//メディアクエリ挿入
@mixin media($breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

@mixin max-media($breakpoint) {
    @media #{map-get($max-breakpoints, $breakpoint)} {
        @content;
    }
}

//フォントサイズ計算
@function rem($size, $base: 16) {
    @return math.div($size, $base)+rem;
}

//pxをvwに変換するfunction
@function vw($size, $viewport: 1920) {
    $rate: calc(100 / $viewport);
    @return $rate * $size * 1vw;
}

//hover
@mixin hover {
    transition: all .5s ease-out;

    &:focus {
        @content;
    }

    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}

// 親要素からはみ出させる
@mixin mx-full {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
}

@mixin ml-full {
    margin-left: calc(50% - 50vw);
}

@mixin mr-full {
    margin-right: calc(50% - 50vw);
}

@mixin px-full {
    padding-left: calc(50vw - 50%);
    padding-right: calc(50vw - 50%);
}

@mixin pl-full {
    padding-left: calc(50vw - 50%);
}

@mixin pr-full {
    padding-right: calc(50vw - 50%);
}
