@use '../../../base/vars'as *;
@use 'sass:math';

.campaign {
    padding: rem(70) 0;

    @include media(sm) {
        padding: rem(120) 0;
    }

    .campaign_inner {}
}
