@use '../../../base/vars'as *;
@use 'sass:math';

.eiken {

    .eiken_belt {}

    .eiken_voice {
        background: $gray_lv2;
        padding-bottom: rem(80);
        @include mx-full;
        @include px-full;

        .eiken_voice_title {
            font-size: rem(30);
            font-weight: 700;
            text-align: center;
        }
    }

    .eiken_merit {
        padding-top: rem(60);

        @include media(sm) {
            padding-top: rem(120);
        }
    }

    .eiken_reason {
        margin-top: rem(60);

        @include media(sm) {
            margin-top: rem(120);
        }

        .eiken_reason_inner {
            margin-top: rem(40);

            @include media(sm) {
                margin-top: rem(80);
            }
        }

        .eiken_reason_list {
            display: grid;
            grid-template-columns: 1fr;
            gap: rem(40);

            @include media(sm) {
                gap: rem(80);
            }

            .eiken_reason_list_item {

                &:not(:first-child) {
                    border-top: rem(1) solid $red;
                    padding-top: rem(40);

                    @include media(sm) {
                        padding-top: rem(80);
                    }
                }
            }
        }
    }

    .eiken_splide {
        @include mx-full;
        padding: rem(80) 0;

        @include media(sm) {
            padding: rem(160) 0;
        }
    }

    .eiken_lesson {
        padding: rem(60) 0;
        background: $gray_lv2;
        @include mx-full;
        @include px-full;

        @include media(sm) {
            padding: rem(120) 0;
            @include px-full;
        }

        .eiken_lesson_subText {
            margin-top: rem(40);
            font-size: rem(18);
        }

        .eiken_lesson_section {
            margin-top: rem(60);

            .eiken_lesson_section_title {}
        }

    }

    .eiken_merit {}
}

// レッスンフローリスト
.lesson_list {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(30);

    @include media(sm) {
        gap: rem(60);
    }

    .lesson_item {}

    .lesson_card {}

    .lesson_header {
        background: $red;
        color: $white;
        display: flex;
        align-items: center;
        font-weight: 700;
        border-radius: rem(10);
        min-height: rem(60);
        gap: rem(10);
        padding: 0 rem(15);

        @include media(sm) {
            min-height: rem(80);
            gap: rem(20);
            padding: 0 rem(30);
        }
    }

    .lesson_number {
        font-size: rem(14);

        @include media(sm) {
            font-size: rem(18);
        }
    }

    .lesson_title {
        font-size: rem(20);

        @include media(sm) {
            font-size: rem(30);
        }
    }

    .lesson_description {
        margin-top: rem(16);
        padding-left: rem(16);

        @include media(sm) {
            margin-top: rem(30);
            padding-left: rem(30);
        }
    }
}


// テーブル
.levels_section {
    padding-top: rem(40);

    @include media(sm) {
        padding-top: rem(80);
    }

    .levels_title {}

    .levels_table_wrapper {}

    .levels_list {
        border-radius: rem(10);
        border: rem(1) solid $gray_lv3;
        overflow: hidden;

        .level_item {
            display: grid;
            grid-template-columns: 1fr;

            @include media(sm) {
                grid-template-columns: vw(136) vw(364) vw(500);
            }

            @include media(md) {
                grid-template-columns: rem(136) rem(364) rem(500);
            }
        }

        .level_item_head {
            display: none;
            background: $red;

            @include media(sm) {
                display: grid;
            }

            .level_degree,
            .level_benefit {
                font-size: rem(18);
                font-weight: 700;
                color: $white;
                border-left: rem(1) solid $white;
            }
        }

        .level_item_body {
            @include media(sm) {

                &:nth-child(even) {
                    background: $white;
                }

                &:nth-child(odd) {
                    background: $gray_lv2;
                }
            }

            .level_title {
                @include max-media(sm) {
                    font-size: rem(18);
                    font-weight: 700;
                    color: $white;
                    background: $red;
                }
            }
        }

        .level_title,
        .level_degree,
        .level_benefit {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: rem(16) 0;
        }

        .level_title {}

        .level_degree,
        .level_benefit {
            border-left: rem(1) solid $gray_lv3;

            >span {
                display: inline;

                @include media(sm) {
                    display: none;
                }
            }
        }

        .level_degree {}

        .level_benefit {
            @include max-media(sm) {
                flex-direction: column;
                text-align: center;
                background: $gray_lv2;
            }
        }

    }

    .levels_note {
        margin-top: rem(20);
        position: relative;
        font-size: rem(16);
        padding-left: 1.5em;

        >span {
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(0, 0);
        }
    }

    .levels_link {
        display: inline-block;
        font-weight: 500;
        color: $red;
    }
}


.support_section {
    .support_number {
        font-size: rem(16);
        font-weight: 700;
        color: $red;
    }

    .support_title {
        font-size: rem(26);
        font-weight: 700;

        @include media(sm) {
            font-size: rem(30);
        }

        >span {
            color: $red;
        }
    }

    .support_wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(30);
        margin-top: rem(30);

        @include media(md) {
            gap: rem(38);
        }

        &._col2 {
            @include media(md) {
                grid-template-columns: 1fr rem(380);
            }
        }
    }

    .support_content {
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(30);
    }

    .support_article {}

    .support_text {
        position: relative;
        padding-left: 2em;
        font-size: rem(16);
        font-weight: 500;

        .material-symbols-outlined {
            position: absolute;
            top: .2em;
            left: 0;
            color: $red;
        }
    }

    .support_note {
        display: block;
        font-size: rem(14);
        font-weight: 400;
        margin-top: rem(12);
    }

    .support_conclusion {
        font-size: rem(16);
        font-weight: 700;
    }

    .support_image_wrapper {}

    .support_image {
        position: relative;
        width: 100%;
        aspect-ratio: calc(336/340);

        @include media(sm) {
            aspect-ratio: calc(380/380);
        }
    }

    .support_image_captio {}
}
