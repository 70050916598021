@use '../../base/vars'as *;
@use 'sass:math';

.splide {
    .splide__pagination {
        gap: rem(16);
    }

    .splide__pagination__page {
        background: $white;
        opacity: 1;

        &.is-active {
            background: $red_lv1;
        }
    }
}
