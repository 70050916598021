@use '../../base/vars'as *;
@use 'sass:math';

.lower.dispatch{
    background-color: $white;
    padding-bottom: 0;

    .dispatch_section_inner{
        padding-top: rem(80);

        @include media(sm){
            padding-top: rem(120);
        }
    }

    .dispatch_lead{
        margin-top: rem(30);
        font-weight: bold;

        @include media(sm){
            margin-top: rem(40);
        }
    }

    .merit{
        margin-top: rem(60);

        @include media(sm){
            margin-top: rem(80);
        }
    }

    .merit_title{
        font-size: rem(26);
        font-weight: bold;
        letter-spacing: 0.03em;

        @include media(sm){
            font-size: rem(30);
        }
    }

    .merit_lists{
        margin-top: rem(30);
        background-color: $gray_lv2;
        border-radius: rem(10);
        padding: rem(30) rem(20);

        @include media(sm){
            padding: rem(30) rem(50);
        }
    }

    .merit_lists_itme{

        dl{
            position: relative;
            padding-left: rem(37);
        }

        dl::before{
            content: '';
            display: block;
            position: absolute;
            top: rem(12);
            left: 0;
            width: rem(24);
            height: rem(17);
            background-image: url('../svg/icons/check02.svg');
            background-repeat: no-repeat;
            background-size: cover;

            @include media(sm){
                top: rem(18);
            }
        }

        dt{
            font-size: rem(20);
            font-weight: bold;
            color: $blue_lv2;

            @include media(sm){
                font-size: rem(24);
            }
        }

        dd{
            font-size: rem(16);
            font-weight: 500;
            margin-top: rem(4);
            line-height: 1.5;

            @include media(sm){
                font-size: rem(20);
            }
        }
    }

    .merit_lists_itme + .merit_lists_itme{
        margin-top: rem(20);
    }

    .achievement{
        margin-top: rem(40);
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(40);
        align-items: flex-start;

        @include media(sm){
            margin-top: rem(80);
            grid-template-columns:repeat(2, 1fr);
            gap: 0;
        }
    }

    .achievement_detail{

        @include media(sm){
            order: 2;
        }
    }

    .achievement_img{
        max-width: rem(440);

        @include media(sm){
            margin-right: rem(20);
            order: 1;
        }

        img{
            border-radius: rem(10);
        }
    }

    .achievement_detail_title{
        font-size: rem(26);
        font-weight: bold;
        letter-spacing: 0.08em;
        line-height: 1.6;

        @include media(sm){
            font-size: rem(30);
            letter-spacing: 0.03em;
        }

        >span {
            display: inline-block;
        }
    }

    .achievement_detail_box{
        margin-top: rem(30);

        @include media(sm){
            padding-left: rem(18);
        }

        .detail_text{
            font-weight: 500;
        }
    }

    .accordion{

        @include media(sm){
            margin-top: rem(50);
        }

        summary{
            list-style: none;
            cursor: pointer;
            background-color: $blue_lv2;
            border-radius: rem(10);
            font-size: rem(18);
            font-weight: bold;
            color: $white;
            text-align: center;
            padding: rem(12);
            position: relative;

            @include media(sm){
                font-size: rem(20);
                padding: rem(16);
            }
        }

        summary::before{
            content: '';
            display: block;
            width: rem(17);
            height: rem(17);
            background-image: url('../svg/icons/plus.svg');
            background-position: top left;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            top: 50%;
            right: rem(20);
            transition: transform .5s ease;
            transform: translateY(-50%);
        }


        .accordion_inner{
            background-color: $gray_lv2;
            border-radius: 0 0 rem(10) rem(10);
            padding: rem(25) rem(10) rem(35);

            @include media(sm){
                padding: rem(25) rem(50) rem(35);
            }
        }

        &[open] {
            summary{
                border-radius: rem(10) rem(10) 0 0;
            }

            summary::before{
                transform: translateY(-50%) rotate(135deg);
            }
        }
    }

    .text-red{
        color: $red;
    }

    .dispatchSplide_wrap{
        margin-top: rem(80);

        @include media(sm){
            margin-top: rem(130);
        }
    }

    .btnLists{
        margin-top: rem(20);

        @include media(sm){
            margin-top: rem(25);
        }

        li + li{
            margin-top: rem(20);

            @include media(sm){
                margin-top: rem(25);
            }
        }
    }
}

