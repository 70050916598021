@use '../../../base/vars'as *;
@use 'sass:math';

.course_container{
    width: 100%;
    max-width: rem(800);
    margin: 0 auto;
    padding: rem(40) 0 rem(80);

    @include media(sm){
        padding: rem(120) 0;
    }
}

.course_text{
    font-size: rem(16);
    font-weight: bold;
    color: $red_lv3;

    @include media(sm){
        font-size: rem(18);
    }
}

.course_strongText{
    font-size: rem(36);
    font-weight: bolder;
    line-height: 1.5;
    padding-top: rem(20);
    letter-spacing: -0.07em;

    @include media(sm){
        font-size: rem(50);
        letter-spacing: 0.03em;
    }

    .red{
        color: $red;
    }

    >span {
        display: inline-block;
    }
}

.flag_img{
    width: 100%;
    max-width: rem(700);
    margin: 0 auto;
    padding-top: rem(30);

    @include media(sm){
        padding-top: rem(50);
    }
}

.online_img{
    width: 100%;
    max-width: rem(526);
    margin: 0 auto;
    padding-top: rem(40);

    @include media(sm){
        padding-top: rem(60);
    }
}

.course_leadText{
    font-size: rem(16);
    font-weight: bold;
    line-height: 2;
    margin-top: rem(30);

    @include media(sm){
        font-size: rem(18);
        margin-top: rem(50);
    }
}
