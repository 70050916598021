@use '../../../base/vars'as *;
@use 'sass:math';

.card {
    padding: rem(14) rem(18);

    @include media(sm) {
        padding: rem(20) rem(23) rem(24);
    }

    .card_img {
        position: relative;
        width: rem(36);
        aspect-ratio: calc(50/50);
        border-radius: 100%;
        overflow: hidden;
        grid-area: icon;

        @include media(sm) {
            width: rem(50);
        }
    }

    .card_content {
        display: grid;
        grid-template-columns: rem(36) 1fr;
        grid-template-areas:
            "icon person"
            "desc desc";
        gap: rem(10) rem(9);

        @include media(sm) {
            grid-template-columns: rem(50) 1fr;
            gap: rem(20) rem(12);
        }
    }

    .card_date {
        grid-area: person;
    }

    .card_person,
    .card_course,
    .card_description {
        font-weight: 500;
    }

    .card_person {
        font-size: rem(12);

        @include media(sm) {
            font-size: rem(14);
        }
    }

    .card_course {
        font-weight: 700;
        color: $red_lv1;
    }

    .card_description {
        font-size: rem(14);
        grid-area: desc;
    }

    .card_description_text {
        font-size: rem(14);
    }
}
