@use '../../base/vars'as *;
@use 'sass:math';

.faq{

    .container{
        padding-top: rem(40);

        @include media(sm){
            padding-top: rem(120);
        }
    }

    .faq_wrap_title{
        font-size: rem(16);
        font-weight: bold;
        color: $red;

        @include media(sm){
            font-size: rem(18);
        }
    }

    .faq_lists{
        margin-top: rem(30);

        @include media(sm){
            margin-top: rem(40);
        }
    }

    .faq_lists_item{

        dt{
            font-size: rem(22);
            font-weight: bold;
            padding-bottom: rem(10);
            border-bottom: solid rem(1) $red;
            position: relative;
            padding-left: rem(30);
            letter-spacing: 0.03em;

            @include media(sm){
                font-size: rem(30);
                padding-left: rem(40);
                padding-bottom: rem(16);
            }
        }

        dt::before{
            content: 'Q.';
            color: $blue_lv2;
            position: absolute;
            top: 0;
            left: 0;
        }

        dd{
            margin-top: rem(12);
            font-size: rem(16);
            font-weight: bold;
            line-height: 1.75;
            padding-left: rem(10);
            letter-spacing: 0.03em;

            @include media(sm){
                font-size: rem(18);
                margin-top: rem(20);
                line-height: 2;
            }
        }
    }

    .faq_lists_item + .faq_lists_item{
        margin-top: rem(40);

        @include media(sm){
            margin-top: rem(50);
        }
    }

}
