@use '../../../base/vars'as *;
@use 'sass:math';

.modal_panel{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease;
}

.modal_button{
    cursor: pointer;
}

.modal_panel.modal_panel-default{
    background-color: rgba(51, 51, 51, .7);
}

.modal_panel.is_active{
    opacity: 1;
    visibility: visible;
    z-index: $modal;
}

.modal_wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 100%;
}

.modal_contents{
    position: relative;
}

.modal_contents.modal_contents-default{
    width: 80%;
    height: 80%;
    background-color: #fff;
}

.modal_close{
    cursor: pointer;
    position: absolute;
    top: -30px;
    right: 0;
}
