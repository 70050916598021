@use '../../base/vars'as *;
@use 'sass:math';

.online{
    .course_container{
        max-width: rem(900);
    }

    .online_content{
        background-color: $red;
        padding: rem(30) 0;

        @include media(sm){
            padding: rem(60) 0 rem(130);
        }
    }

    .online_container{
        width: 100%;
        max-width: rem(1160);
        margin: 0 auto;
    }

    .online_chatch{
        font-weight: 800;
        font-size: rem(20);
        color: $white;
        text-align: center;
        line-height: 1.5;
        letter-spacing: 0.03em;

        @include media(sm){
            font-size: rem(30);
        }

        .large{
            font-size: rem(34);

            @include media(sm){
                font-size: rem(50);
            }
        }
    }

    .chatch_bottom{
        font-weight: 800;
        font-size: rem(20);
        color: $white;
        text-align: center;
        line-height: 1.5;
        letter-spacing: 0.03em;
        padding-top: rem(20);

        @include media(sm){
            padding-top: 0;
        }

        .large{
            font-size: rem(34);

            @include media(sm){
                font-size: rem(50);
            }
        }

        &.first{
            padding-top: rem(40);
        }
    }

    .online_list{
        display: grid;
        gap: rem(20);
        padding-top: rem(40);

        @include media(sm){
            grid-template-columns: repeat(2,1fr);
            gap: rem(40);
        }
    }

    .online_item{
        padding: rem(30);
        background-color: $white;
        border-radius: rem(10);
    }

    .point_number{
        font-weight: bold;
        font-size: rem(16);
        color: $red;

        @include media(sm){
            font-size: rem(18);
        }
    }

    .point_title{
        font-weight: bold;
        font-size: rem(26);
        line-height: 1.5;
        letter-spacing: 0.03em;
        padding-top: rem(10);

        @include media(sm){
            font-size: rem(30);
            padding-top: rem(15);
        }

        .red{
            color: $red;
        }
    }

    .point_inner{
        display: grid;
        gap: rem(20);
        padding-top: rem(20);

        @include media(sm){
            display: flex;
            align-items: start;
            gap: rem(24);
            padding-top: rem(30);
        }
    }

    .point_text{
        order: 2;
        width: 100%;
        font-size: rem(16);

        @include media(sm){
            order: 1;
        }

        .small{
            font-size: rem(12);
        }
    }

    .point_img{
        order: 1;
        width: 100%;
        max-width: rem(200);
        margin: 0 auto;

        @include media(sm){
            order: 2;
            margin: 0;
        }
    }

    .classroom_box{
        background-color: $gray_lv2;
        padding: rem(50) 0;
        margin-top: rem(60);
        
        @include media(sm){
            padding: rem(60) 0;
            margin-top: rem(120);
        }
    }

    .classroom_list{
        display: grid;
        gap: rem(40);

        @include media(sm){
            grid-template-columns: repeat(2, 1fr);
            gap: rem(60);
        }

        .button_lv1{
            padding: rem(10) rem(15);

            @include media(sm){
                padding: rem(10) rem(25);
            }
        }
    }

    .classroom_item{
        .map{
            width: 100%;
            aspect-ratio: calc(336/220);
            line-height: 1;

            @include media(sm){
                aspect-ratio: calc(470/220);
            }
        }

        .button{
            padding-top: rem(30);
        }

        .address{
            font-size: rem(16);
            font-weight: bold;
            padding-top: rem(20);
            padding-left: rem(10);

            @include media(sm){
                font-size: rem(18);
                padding-left: rem(25);
            }
        }
    }
}
