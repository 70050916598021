@use '../../../base/vars'as *;
@use 'sass:math';

.lower_header{
    width: 100%;
    max-width: rem(1240);
    padding: 0 rem(20);
    margin: 0 auto;
}

.lower_header_mv{
    display: flex;
    flex-direction: column;

    @include media(sm){
        height: rem(240);
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
    }

    .lower_header_mv_title{
        font-size: rem(36);
        font-weight: 900;
        letter-spacing: 0.03em;
        line-height: 1.2;
        padding: rem(36) 0;

        @include media(sm){
            font-size: rem(40);
            padding: 0;
            flex: 1;
            padding-right: rem(20);
        }

        @include media(md){
            font-size: rem(50);
            padding: 0;
        }

    }
    .lower_header_mv_img{
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);

        @include media(sm){
            width: 100%;
            max-width: rem(375);
            margin-left: 0;
        }

        @include media(md){
            max-width: rem(700);
        }
    }

    .lower_header_mv_img img{
        width: 100%;
        max-width: rem(700);
    }
}

.lower_header_breadcrumbs{
    background-color: $red;
    margin:  0 calc(50% - 50vw);
    padding: 0 calc(50vw - 50%);

    .breadcrumbsLists{
        display: flex;
        padding: rem(4) 0;

        @include media(sm){
            padding: rem(8) 0;
        }
    }

    .breadcrumbsLists_item{
        padding-bottom: rem(2);
    }

    .breadcrumbsLists_item a{
        position: relative;
        color: $white;
        font-size: rem(12);
        font-weight: 500;
        line-height: 1.2;
        display: inline-block;
        padding-bottom: rem(2);
        transition: opacity .5s ease;

        @include media(sm){
            font-size: rem(16);
        }

        &:hover{
            opacity: 0.6;
        }
    }

    .breadcrumbsLists_item:first-child a{
        border-bottom: solid rem(1) $white;
    }

    .breadcrumbsLists_item:not(:first-child) a{
        padding-left: rem(30);

        @include media(sm){
            padding-left: rem(40);
        }

        &::before{
            content: '＞';
            position: absolute;
            top: rem(1);
            left: rem(10);

            @include media(sm){
                left: rem(13);
            }
        }
    }
}
