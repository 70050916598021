@use '../../../base/vars'as *;
@use 'sass:math';


.lists-card_lv1 li:not(:last-child) .card_lv1,
.about_act_lists li:not(:last-child) .card_lv1 {
    border-bottom: solid rem(1) $red;
}

.lists-card_lv1 .adult_course_lists_item:last-child .card_lv1,
.lists-card_lv1 .actLists_item:last-child .card_lv1{
    padding-bottom: 0;
}

// Card
.card_lv1 {
    padding: rem(35) 0 rem(50);

    @include media(sm) {
        padding: rem(80) 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
    }

    .subTitle {
        font-size: rem(16);
        font-weight: bold;
        color: $red;
        letter-spacing: 0.05em;

        @include media(sm) {
            font-size: rem(18);
        }

        span {
            display: inline-block;
        }

        span:first-child {
            margin-right: rem(6);
        }

        .tag {
            font-size: rem(14);
            font-weight: 500;
            background-color: $white;
            border: solid rem(1) $red;
            border-radius: rem(5);
            line-height: 1.2;
            padding: rem(5) rem(7);
            margin-bottom: rem(10);
            color: $red_lv3;
        }

        .tag+.tag {
            margin-left: rem(6);
        }
    }

    .title {
        font-size: rem(26);
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: 1.5;
        margin-top: rem(16);

        @include media(sm) {
            font-size: rem(30);
            margin-top: rem(20);
        }
    }

    .title-red {
        color: $red;
    }

    .textBox {
        margin-top: rem(20);

        @include media(sm) {
            padding-left: rem(25);
        }

        p {
            font-size: rem(16);
            font-weight: 500;
            line-height: 2;
            text-align: justify;
            letter-spacing: 0.07em;

            @include media(sm){
                letter-spacing: 0.02em;
            }
        }

        p+p {
            margin-top: rem(40);
        }
    }

    .card_lv1_fase.fase1 {
        order: 2;
    }

    .card_lv1_fase.fase2 {
        margin-top: rem(30);

        @include media(sm) {
            margin-top: 0;
            margin-right: rem(20);
            max-width: rem(440);
            order: 1;
        }
    }

    .card_lv1_fase.fase2 img {
        border-radius: rem(10);
    }

    .card_lv1_lineBtn {
        font-size: rem(18);
        font-weight: bold;
        line-height: 1;
        color: $white;
        background-color: $green;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: rem(10);
        max-width: rem(378);
        margin-top: rem(30);
        height: rem(50);
        letter-spacing: 0.05em;

        @include media(sm) {
            font-size: rem(22);
            margin-top: rem(50);
        }

        img {
            width: rem(20);
            height: rem(20);
            margin-right: rem(10);

            @include media(sm) {
                width: rem(26);
                height: rem(26);
                margin-right: rem(15);
            }
        }
    }

    // TOPで使用する
    &.otherType {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas:
            "areaA"
            "areaB";
        gap: rem(20);
        padding: 0;
        padding-top: rem(40);

        @include media(sm) {
            grid-template-columns: rem(470) 1fr;
            grid-template-areas:
                "areaA areaB";
            gap: rem(50);
            padding-top: rem(60);
        }

        &:not(:first-of-type) {
            border-top: rem(1) solid $red_lv1;
        }

        &.main {
            grid-template-areas:
                "areaA"
                "areaB"
                "areaC";
            padding-top: rem(20);

            @include media(sm) {
                grid-template-areas:
                    "areaA areaB"
                    "areaC areaC";
                padding-top: rem(60);
            }
        }

        .subTitle {
            display: flex;
        }

        .card_lv1_fase {

            &.areaA {
                grid-area: areaA;
                border-radius: 10px;
                overflow: hidden;
            }

            &.areaB {
                grid-area: areaB;
            }

            &.areaC {
                grid-area: areaC;
            }
        }

        .textBox {
            &.space_none {
                padding-left: 0;
                margin: 0;
            }
        }

        .card_lv1_button,
        .card_lv1_inner_button {
            width: 100%;
            max-width: rem(500);
            margin: 0 auto;
        }

        .card_lv1_button {
            margin-top: rem(10);

            @include media(sm) {
                margin-top: 0;
            }
        }

        .card_lv1_inner_button {
            margin-top: rem(30);

            @include media(sm) {
                margin-top: rem(60);
            }
        }

        .card_lv1_recommend_wrapper {
            margin-top: rem(20);
            padding-left: 0;

            @include media(sm) {
                margin-top: rem(40);
                padding-left: rem(30);
            }
        }

        .card_lv1_recommend_list_wrapper {
            padding: rem(17) rem(26) rem(20);
            background: $gray_lv2;
            border-radius: rem(10);
            margin-top: rem(20);
        }

        .card_lv1_recommend_list {}

        .card_lv1_recommend_item {
            display: flex;
            align-items: flex-start;
            gap: rem(4);
            font-size: rem(16);
            font-weight: 700;

            .material-symbols-outlined {
                margin-top: .2em;
                color: $blue;
            }
        }
    }

}
