@charset "UTF-8";
/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html {
  box-sizing: border-box;
  overflow-y: scroll; /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%; /* iOS 8+ */
}

*,
::before,
::after {
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit; /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

input, select {
  vertical-align: middle;
}

input[type=button], input[type=submit] {
  -webkit-appearance: none;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  overflow-x: hidden;
  font-family: "Noto Sans JP", sans-serif;
  line-height: 2;
  color: #333333;
  background-color: #ffffff;
}

a {
  color: #333333;
  text-decoration: none;
  display: block;
}

img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: block;
}

p {
  line-height: 2;
  font-size: 1.125rem;
}

input,
select,
button,
textarea {
  box-sizing: border-box;
}

input[type=text],
input[type=password],
input[type=tel],
input[type=email],
input[type=number],
input[type=submit],
input[type=button],
input[type=reset],
textarea,
button,
select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
}

input[type=submit],
input[type=button],
input[type=reset],
button,
label[for] {
  cursor: pointer;
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

ul,
ol {
  list-style: none;
}

.pc_only {
  display: none;
}
@media screen and (min-width: 769px) {
  .pc_only {
    display: block;
  }
}

@media screen and (min-width: 769px) {
  .sp_only {
    display: none;
  }
}

.hover {
  transition: opacity 0.3s ease-out;
  transition: all 0.5s ease-out;
}
.hover:focus {
  opacity: 0.8;
}
@media (hover: hover) {
  .hover:hover {
    opacity: 0.8;
  }
}

.sr_only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.text_large {
  font-size: 1.3em;
}

.text_small {
  font-size: 0.75em !important;
}

.icon_flag,
.icon_recommend,
.icon_online {
  display: inline-block;
  position: relative;
  color: transparent;
  font-size: 0.0625rem;
  line-height: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: transparent;
}

.icon_flag {
  width: 1.3125rem;
  aspect-ratio: 0.875;
  background-image: url("../svg/icons/flag.svg");
}

.icon_online {
  width: 5.4375rem;
  aspect-ratio: 3.2222222222;
  background-image: url("../svg/icons/online.svg");
}

.icon_recommend {
  width: 11.125rem;
  aspect-ratio: 3.0169491525;
  background-image: url("../svg/icons/recommend.svg");
}

.he_mx-full {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
}

.text_red {
  color: #FF0600;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wrapper {
  width: 100%;
  max-width: 77.5rem;
  padding: 0 1.25rem;
  margin: 0 auto;
}

.container {
  width: 100%;
  max-width: 62.5rem;
  margin: 0 auto;
}

.main {
  padding-top: 4.375rem;
}
@media screen and (min-width: 769px) {
  .main {
    padding-top: 7.5rem;
  }
}

.accordion .accordion_panel {
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.accordion .accordion_panel.is_open {
  height: auto;
  opacity: 1;
  visibility: visible;
  transition: line-height 0.3s ease-out, opacity 0.2s linear 0.1s, visibility 0.1s linear 0.1s;
}

.button_lv1 {
  text-align: center;
  padding: 0.9375rem;
  border-radius: 0.625rem;
  background-color: #003CF2;
}
@media screen and (min-width: 769px) {
  .button_lv1 {
    padding: 1.125rem 1.5625rem;
  }
}
.button_lv1 .button_lv1__text {
  position: relative;
  display: block;
  width: 100%;
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.03em;
  line-height: 1.5;
  padding-right: 1.25rem;
}
@media screen and (min-width: 769px) {
  .button_lv1 .button_lv1__text {
    font-size: 1.25rem;
  }
}
.button_lv1 .button_lv1__text::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1.0625rem;
  height: 0.875rem;
  background-image: url("../svg/icons/arrowWhite_icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.3s ease-out;
}
.button_lv1 .button_lv1__text > span {
  display: inline-block;
}
.button_lv1:hover .button_lv1__text::after {
  transform: translateY(-50%) translateX(50%);
}
.button_lv1.red {
  background-color: #ffffff;
}
.button_lv1.red .button_lv1__text {
  color: #FF0600;
}
.button_lv1.red .button_lv1__text::after {
  background-image: url("../svg/icons/arrowRed_icon.svg");
}
.button_lv1.anchor .button_lv1__text::after {
  transform: translateY(-50%) rotate(90deg);
}
.button_lv1.anchor:hover .button_lv1__text::after {
  transform: translateY(0) rotate(90deg);
}
.button_lv1.icon_insta .button_lv1__text::after {
  width: 1rem;
  height: 1rem;
  background-image: url("../svg/icons/insta.svg");
  transition: none;
}
.button_lv1.icon_insta:hover .button_lv1__text::after {
  transform: translateY(-50%);
}
.button_lv1.solid-red {
  border: solid 0.125rem #FF0600;
}

.heading_lv1 .heading_lv1_Subtitle {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  color: #FF0600;
}
@media screen and (min-width: 769px) {
  .heading_lv1 .heading_lv1_Subtitle {
    font-size: 1.125rem;
  }
}
.heading_lv1 .heading_lv1_title {
  display: block;
  font-size: 1.75rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
  border-bottom: solid 0.0625rem #FF0600;
}
@media screen and (min-width: 769px) {
  .heading_lv1 .heading_lv1_title {
    font-size: 2.125rem;
    padding-bottom: 0.75rem;
  }
}
.heading_lv1 .text-red {
  color: #FF0600;
}

.card {
  padding: 0.875rem 1.125rem;
}
@media screen and (min-width: 769px) {
  .card {
    padding: 1.25rem 1.4375rem 1.5rem;
  }
}
.card .card_img {
  position: relative;
  width: 2.25rem;
  aspect-ratio: 1;
  border-radius: 100%;
  overflow: hidden;
  grid-area: icon;
}
@media screen and (min-width: 769px) {
  .card .card_img {
    width: 3.125rem;
  }
}
.card .card_content {
  display: grid;
  grid-template-columns: 2.25rem 1fr;
  grid-template-areas: "icon person" "desc desc";
  gap: 0.625rem 0.5625rem;
}
@media screen and (min-width: 769px) {
  .card .card_content {
    grid-template-columns: 3.125rem 1fr;
    gap: 1.25rem 0.75rem;
  }
}
.card .card_date {
  grid-area: person;
}
.card .card_person,
.card .card_course,
.card .card_description {
  font-weight: 500;
}
.card .card_person {
  font-size: 0.75rem;
}
@media screen and (min-width: 769px) {
  .card .card_person {
    font-size: 0.875rem;
  }
}
.card .card_course {
  font-weight: 700;
  color: #DA1725;
}
.card .card_description {
  font-size: 0.875rem;
  grid-area: desc;
}
.card .card_description_text {
  font-size: 0.875rem;
}

.lists-card_lv1 li:not(:last-child) .card_lv1,
.about_act_lists li:not(:last-child) .card_lv1 {
  border-bottom: solid 0.0625rem #FF0600;
}

.lists-card_lv1 .adult_course_lists_item:last-child .card_lv1,
.lists-card_lv1 .actLists_item:last-child .card_lv1 {
  padding-bottom: 0;
}

.card_lv1 {
  padding: 2.1875rem 0 3.125rem;
}
@media screen and (min-width: 769px) {
  .card_lv1 {
    padding: 5rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
  }
}
.card_lv1 .subTitle {
  font-size: 1rem;
  font-weight: bold;
  color: #FF0600;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .card_lv1 .subTitle {
    font-size: 1.125rem;
  }
}
.card_lv1 .subTitle span {
  display: inline-block;
}
.card_lv1 .subTitle span:first-child {
  margin-right: 0.375rem;
}
.card_lv1 .subTitle .tag {
  font-size: 0.875rem;
  font-weight: 500;
  background-color: #ffffff;
  border: solid 0.0625rem #FF0600;
  border-radius: 0.3125rem;
  line-height: 1.2;
  padding: 0.3125rem 0.4375rem;
  margin-bottom: 0.625rem;
  color: #DF0510;
}
.card_lv1 .subTitle .tag + .tag {
  margin-left: 0.375rem;
}
.card_lv1 .title {
  font-size: 1.625rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 1.5;
  margin-top: 1rem;
}
@media screen and (min-width: 769px) {
  .card_lv1 .title {
    font-size: 1.875rem;
    margin-top: 1.25rem;
  }
}
.card_lv1 .title-red {
  color: #FF0600;
}
.card_lv1 .textBox {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .card_lv1 .textBox {
    padding-left: 1.5625rem;
  }
}
.card_lv1 .textBox p {
  font-size: 1rem;
  font-weight: 500;
  line-height: 2;
  text-align: justify;
  letter-spacing: 0.07em;
}
@media screen and (min-width: 769px) {
  .card_lv1 .textBox p {
    letter-spacing: 0.02em;
  }
}
.card_lv1 .textBox p + p {
  margin-top: 2.5rem;
}
.card_lv1 .card_lv1_fase.fase1 {
  order: 2;
}
.card_lv1 .card_lv1_fase.fase2 {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .card_lv1 .card_lv1_fase.fase2 {
    margin-top: 0;
    margin-right: 1.25rem;
    max-width: 27.5rem;
    order: 1;
  }
}
.card_lv1 .card_lv1_fase.fase2 img {
  border-radius: 0.625rem;
}
.card_lv1 .card_lv1_lineBtn {
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1;
  color: #ffffff;
  background-color: #00B900;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  max-width: 23.625rem;
  margin-top: 1.875rem;
  height: 3.125rem;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .card_lv1 .card_lv1_lineBtn {
    font-size: 1.375rem;
    margin-top: 3.125rem;
  }
}
.card_lv1 .card_lv1_lineBtn img {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
}
@media screen and (min-width: 769px) {
  .card_lv1 .card_lv1_lineBtn img {
    width: 1.625rem;
    height: 1.625rem;
    margin-right: 0.9375rem;
  }
}
.card_lv1.otherType {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "areaA" "areaB";
  gap: 1.25rem;
  padding: 0;
  padding-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .card_lv1.otherType {
    grid-template-columns: 29.375rem 1fr;
    grid-template-areas: "areaA areaB";
    gap: 3.125rem;
    padding-top: 3.75rem;
  }
}
.card_lv1.otherType:not(:first-of-type) {
  border-top: 0.0625rem solid #DA1725;
}
.card_lv1.otherType.main {
  grid-template-areas: "areaA" "areaB" "areaC";
  padding-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .card_lv1.otherType.main {
    grid-template-areas: "areaA areaB" "areaC areaC";
    padding-top: 3.75rem;
  }
}
.card_lv1.otherType .subTitle {
  display: flex;
}
.card_lv1.otherType .card_lv1_fase.areaA {
  grid-area: areaA;
  border-radius: 10px;
  overflow: hidden;
}
.card_lv1.otherType .card_lv1_fase.areaB {
  grid-area: areaB;
}
.card_lv1.otherType .card_lv1_fase.areaC {
  grid-area: areaC;
}
.card_lv1.otherType .textBox.space_none {
  padding-left: 0;
  margin: 0;
}
.card_lv1.otherType .card_lv1_button,
.card_lv1.otherType .card_lv1_inner_button {
  width: 100%;
  max-width: 31.25rem;
  margin: 0 auto;
}
.card_lv1.otherType .card_lv1_button {
  margin-top: 0.625rem;
}
@media screen and (min-width: 769px) {
  .card_lv1.otherType .card_lv1_button {
    margin-top: 0;
  }
}
.card_lv1.otherType .card_lv1_inner_button {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .card_lv1.otherType .card_lv1_inner_button {
    margin-top: 3.75rem;
  }
}
.card_lv1.otherType .card_lv1_recommend_wrapper {
  margin-top: 1.25rem;
  padding-left: 0;
}
@media screen and (min-width: 769px) {
  .card_lv1.otherType .card_lv1_recommend_wrapper {
    margin-top: 2.5rem;
    padding-left: 1.875rem;
  }
}
.card_lv1.otherType .card_lv1_recommend_list_wrapper {
  padding: 1.0625rem 1.625rem 1.25rem;
  background: #F7F5F5;
  border-radius: 0.625rem;
  margin-top: 1.25rem;
}
.card_lv1.otherType .card_lv1_recommend_item {
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  font-size: 1rem;
  font-weight: 700;
}
.card_lv1.otherType .card_lv1_recommend_item .material-symbols-outlined {
  margin-top: 0.2em;
  color: #1E3CF2;
}

.splide .splide__pagination {
  gap: 1rem;
}
.splide .splide__pagination__page {
  background: #ffffff;
  opacity: 1;
}
.splide .splide__pagination__page.is-active {
  background: #DA1725;
}

.header {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 4.375rem;
  min-height: 4.375rem;
  padding: 0 1.25rem;
  z-index: 1000;
}
@media screen and (min-width: 769px) {
  .header {
    padding: 0 5.2083333333vw;
    max-height: 7.5rem;
    min-height: 7.5rem;
  }
}
@media screen and (min-width: 1920px) {
  .header {
    padding: 0 6.25rem;
  }
}
.header .header_logo {
  position: relative;
  width: 100%;
  max-width: 10.5rem;
  aspect-ratio: 3.5;
}
@media screen and (min-width: 769px) {
  .header .header_logo {
    aspect-ratio: 3.7972972973;
    max-width: 14.6354166667vw;
  }
}
@media screen and (min-width: 1920px) {
  .header .header_logo {
    max-width: 17.5625rem;
  }
}
.header .header_content {
  display: none;
}
@media screen and (min-width: 1000px) {
  .header .header_content {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 10.9895833333vw;
    gap: 1em 2.6041666667vw;
  }
}
@media screen and (min-width: 1920px) {
  .header .header_content {
    grid-template-columns: 1fr 13.1875rem;
    gap: 1em 3.125rem;
  }
}
.header .header_navi_list {
  display: flex;
  gap: 1em 1.875rem;
}
@media screen and (min-width: 1000px) {
  .header .header_navi_list {
    gap: 1em 1.5625vw;
  }
}
@media screen and (min-width: 1920px) {
  .header .header_navi_list {
    gap: 1em 1.875rem;
  }
}
.header .header_navi_item {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 700;
}
@media screen and (min-width: 1000px) {
  .header .header_navi_item {
    font-size: 0.9375vw;
  }
}
@media screen and (min-width: 1920px) {
  .header .header_navi_item {
    font-size: 1.125rem;
  }
}
.header .header_navi_item::before {
  display: block;
  content: "";
  width: 0;
  height: 0.0625rem;
  position: absolute;
  bottom: -0.125rem;
  left: 0;
  background: #1E3CF2;
  transition: width 0.3s ease-in-out;
}
.header .header_navi_item:hover::before, .header .header_navi_item.current::before {
  width: 100%;
}
.header .header_navi_item:hover .header_navi_dropdown {
  margin-top: 0.625rem;
  padding-top: 0.5rem;
  background: #ffffff;
}
.header .header_navi_item:hover .header_navi_dropdown_list {
  line-height: 1.5;
  visibility: visible;
}
@media screen and (min-width: 769px) {
  .header .header_navi_item:hover .header_navi_dropdown_list {
    padding: 0.78125vw 0.5208333333vw;
  }
}
@media screen and (min-width: 1920px) {
  .header .header_navi_item:hover .header_navi_dropdown_list {
    padding: 0.9375rem;
  }
}
.header .header_navi_item .material-symbols-outlined {
  font-size: 2em;
}
.header .header_navi_dropdown {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 12.5rem;
  transform: translate(-50%, 100%);
  border-radius: 0.25rem;
  filter: drop-shadow(0 0 0.375rem rgba(51, 51, 51, 0.3));
  transition: visibility 0.2s ease-in 0.2s, margin 0.2s ease-in, padding 0.2s ease-in;
}
@media screen and (min-width: 769px) {
  .header .header_navi_dropdown {
    margin-top: 0;
    padding-top: 0;
    width: 10.4166666667vw;
    padding-left: 0.78125vw;
    padding-right: 0.78125vw;
  }
}
@media screen and (min-width: 1920px) {
  .header .header_navi_dropdown {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    width: 12.5rem;
  }
}
.header .header_navi_dropdown_list {
  visibility: hidden;
  line-height: 0;
  transition: visibility 0.2s ease-in, margin 0.2s ease-in, padding 0.2s ease-in, line-height 0.2s ease-in;
}
.header .header_navi_dropdown_list:not(:first-of-type) {
  padding-top: 0;
  margin-top: 0;
  border-top: 0.0625rem solid #1E3CF2;
}
@media screen and (min-width: 769px) {
  .header .header_navi_dropdown_list {
    padding-left: 0.78125vw;
    padding-right: 0.78125vw;
    font-size: 0.8333333333vw;
  }
}
@media screen and (min-width: 1920px) {
  .header .header_navi_dropdown_list {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    font-size: 1rem;
  }
}
.header .header_contact_link {
  position: relative;
  width: 100%;
  aspect-ratio: 3.0579710145;
}

.footer {
  padding-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .footer {
    padding-top: 7.5rem;
  }
}
.footer .footer_contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FF0600;
  padding: 2.5rem 0;
}
@media screen and (min-width: 769px) {
  .footer .footer_contact {
    padding: 3.75rem 0 4.375rem;
  }
}
.footer .footer_contact_text {
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 700;
  font-family: "M PLUS 2", sans-serif;
}
@media screen and (min-width: 769px) {
  .footer .footer_contact_text {
    font-size: 1.5rem;
  }
}
.footer .footer_contact_button {
  margin-top: 1.25rem;
  max-width: 21rem;
}
@media screen and (min-width: 769px) {
  .footer .footer_contact_button {
    margin-top: 1.125rem;
    max-width: 31.25rem;
  }
}
.footer .footer_contact_link {
  position: relative;
  width: 100%;
  aspect-ratio: 6.2222222222;
}
@media screen and (min-width: 769px) {
  .footer .footer_contact_link {
    aspect-ratio: 7.2463768116;
  }
}
.footer .footer_content {
  max-width: 85.875rem;
  margin: 0 auto;
  padding: 2.5rem 1.25rem 3.125rem;
}
@media screen and (min-width: 769px) {
  .footer .footer_content {
    padding: 3.4375rem 1.25rem 4.875rem;
  }
}
.footer .footer_content_logo {
  position: relative;
  width: 100%;
  max-width: 12.8125rem;
  aspect-ratio: 3.7972972973;
}
@media screen and (min-width: 769px) {
  .footer .footer_content_logo {
    max-width: 17.5625rem;
  }
}
.footer .footer_content_inner {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem 5rem;
  margin-top: 3.125rem;
}
@media screen and (min-width: 1366px) {
  .footer .footer_content_inner {
    grid-template-columns: 62.5rem 1fr;
  }
}
.footer .footer_school_info {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem 3.75rem;
  background: #F7F5F5;
  padding: 1.25rem 0.625rem 1.875rem;
}
@media screen and (min-width: 769px) {
  .footer .footer_school_info {
    grid-template-columns: repeat(2, 1fr);
    padding: 1.875rem 3.4375rem 2.125rem;
  }
}
.footer .footer_school_info_title {
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: 0.625rem;
  border-bottom: 0.0625rem solid #1E3CF2;
}
.footer .footer_school_info_inner {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.125rem;
  margin-top: 0.625rem;
  padding-left: 0.875rem;
}
.footer .footer_school_info_row {
  display: grid;
  grid-template-columns: 5.25rem 1fr;
  gap: 0.125rem 0.625rem;
  font-size: 0.875rem;
}
.footer .footer_school_info_term {
  font-weight: 500;
  padding: 0.375rem 0.75rem;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2;
}
.footer .footer_school_info_desc {
  display: flex;
  align-items: center;
}
.footer .footer_school_info_desc.address {
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.footer .footer_school_info_address {
  font-style: normal;
}
.footer .footer_nav_list {
  font-size: 0.875rem;
  font-weight: 500;
  display: grid;
  gap: 1.25rem 1.875rem;
  grid-template-columns: 8.75rem 1fr;
  grid-template-areas: "area1 area8" "area2 area9" "area3 area10" "area4 area11" "area5 area12" "area6 area13" "area7 area7";
}
.footer .footer_nav_item.area1 {
  grid-area: area1;
}
.footer .footer_nav_item.area2 {
  grid-area: area2;
}
.footer .footer_nav_item.area3 {
  grid-area: area3;
}
.footer .footer_nav_item.area4 {
  grid-area: area4;
}
.footer .footer_nav_item.area5 {
  grid-area: area5;
}
.footer .footer_nav_item.area6 {
  grid-area: area6;
}
.footer .footer_nav_item.area7 {
  grid-area: area7;
}
.footer .footer_nav_item.area8 {
  grid-area: area8;
}
.footer .footer_nav_item.area9 {
  grid-area: area9;
}
.footer .footer_nav_item.area10 {
  grid-area: area10;
}
.footer .footer_nav_item.area11 {
  grid-area: area11;
}
.footer .footer_nav_item.area12 {
  grid-area: area12;
}
.footer .footer_nav_item.area13 {
  grid-area: area13;
}
.footer .footer_copyright {
  background: #FF0600;
  display: flex;
  justify-content: center;
  color: #ffffff;
  padding: 0.75rem 0;
}
.footer .footer_copyright > * {
  font-size: 0.625rem;
  font-weight: 700;
}

.humburger_nav {
  display: block;
}
@media screen and (min-width: 1000px) {
  .humburger_nav {
    display: none;
  }
}
.humburger_nav .humburger_toggle {
  position: relative;
  width: 2.5rem;
  height: 1.375rem;
}
.humburger_nav .humburger_toggle.is_expanded .humburger_icon {
  background: none;
}
.humburger_nav .humburger_toggle.is_expanded .humburger_icon::before {
  transform: translateY(0.6875rem) rotate(45deg);
}
.humburger_nav .humburger_toggle.is_expanded .humburger_icon::after {
  transform: translateY(-0.6875rem) rotate(-45deg);
}
.humburger_nav .humburger_icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 0.125rem;
  background: #FF0600;
}
.humburger_nav .humburger_icon::before, .humburger_nav .humburger_icon::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 0.125rem;
  background: #FF0600;
  transition: transform 0.3s ease-in-out;
}
.humburger_nav .humburger_icon::before {
  top: -0.6875rem;
}
.humburger_nav .humburger_icon:after {
  bottom: -0.6875rem;
}
.humburger_nav .humburger_menu {
  z-index: 1001;
  position: absolute;
  top: 4.375rem;
  right: 0;
  transform: translateX(100%);
  background: #FF0600;
  width: 13rem;
  padding: 2.5rem 1.25rem;
  border-radius: 0.625rem;
  transition: transform 0.3s ease-in-out;
  height: 70vh;
  overflow: auto;
}
@media screen and (min-width: 769px) {
  .humburger_nav .humburger_menu {
    top: 7.5rem;
  }
}
.humburger_nav .humburger_menu.is_open {
  transform: translate(0);
}
.humburger_nav .humburger_item {
  border-bottom: 0.0625rem solid #ffffff;
}
.humburger_nav .humburger_item:last-of-type {
  margin-top: 2.5rem;
  border-bottom: none;
}
.humburger_nav .humburger_toggle_button,
.humburger_nav .humburger_sublink,
.humburger_nav .humburger_link {
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 1.25rem 0;
}
.humburger_nav .humburger_toggle_button {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.humburger_nav .humburger_toggle_button .material-symbols-outlined {
  transition: transform 0.3s ease-in-out;
}
.humburger_nav .humburger_toggle_button.is_expanded .material-symbols-outlined {
  transform: rotate(180deg);
}
.humburger_nav .humburger_sublist {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 0.7em;
  padding-bottom: 1.25rem;
}
.humburger_nav .humburger_sublink {
  padding: 0;
}
.sns {
  position: fixed;
  top: 26.4375rem;
  right: 1rem;
  display: none;
  z-index: 999;
}
@media screen and (min-width: 769px) {
  .sns {
    display: block;
  }
}
.sns .sns_list {
  display: flex;
  flex-direction: column;
  gap: 0.8125rem;
}
.sns .sns_link {
  position: relative;
  width: 4.375rem;
  aspect-ratio: 1;
}

.line {
  position: fixed;
  bottom: 1.25rem;
  right: 0.3125rem;
  z-index: 999;
}
@media screen and (min-width: 769px) {
  .line {
    right: 1rem;
  }
}
.line .sns_link {
  position: relative;
  width: 100%;
  max-width: 5.3125rem;
  aspect-ratio: 0.7943925234;
}
@media screen and (min-width: 769px) {
  .line .sns_link {
    max-width: 16.875rem;
    aspect-ratio: 3.0681818182;
  }
}

.lower_header {
  width: 100%;
  max-width: 77.5rem;
  padding: 0 1.25rem;
  margin: 0 auto;
}

.lower_header_mv {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 769px) {
  .lower_header_mv {
    height: 15rem;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}
.lower_header_mv .lower_header_mv_title {
  font-size: 2.25rem;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 1.2;
  padding: 2.25rem 0;
}
@media screen and (min-width: 769px) {
  .lower_header_mv .lower_header_mv_title {
    font-size: 2.5rem;
    padding: 0;
    flex: 1;
    padding-right: 1.25rem;
  }
}
@media screen and (min-width: 1000px) {
  .lower_header_mv .lower_header_mv_title {
    font-size: 3.125rem;
    padding: 0;
  }
}
.lower_header_mv .lower_header_mv_img {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 769px) {
  .lower_header_mv .lower_header_mv_img {
    width: 100%;
    max-width: 23.4375rem;
    margin-left: 0;
  }
}
@media screen and (min-width: 1000px) {
  .lower_header_mv .lower_header_mv_img {
    max-width: 43.75rem;
  }
}
.lower_header_mv .lower_header_mv_img img {
  width: 100%;
  max-width: 43.75rem;
}

.lower_header_breadcrumbs {
  background-color: #FF0600;
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%);
}
.lower_header_breadcrumbs .breadcrumbsLists {
  display: flex;
  padding: 0.25rem 0;
}
@media screen and (min-width: 769px) {
  .lower_header_breadcrumbs .breadcrumbsLists {
    padding: 0.5rem 0;
  }
}
.lower_header_breadcrumbs .breadcrumbsLists_item {
  padding-bottom: 0.125rem;
}
.lower_header_breadcrumbs .breadcrumbsLists_item a {
  position: relative;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.2;
  display: inline-block;
  padding-bottom: 0.125rem;
  transition: opacity 0.5s ease;
}
@media screen and (min-width: 769px) {
  .lower_header_breadcrumbs .breadcrumbsLists_item a {
    font-size: 1rem;
  }
}
.lower_header_breadcrumbs .breadcrumbsLists_item a:hover {
  opacity: 0.6;
}
.lower_header_breadcrumbs .breadcrumbsLists_item:first-child a {
  border-bottom: solid 0.0625rem #ffffff;
}
.lower_header_breadcrumbs .breadcrumbsLists_item:not(:first-child) a {
  padding-left: 1.875rem;
}
@media screen and (min-width: 769px) {
  .lower_header_breadcrumbs .breadcrumbsLists_item:not(:first-child) a {
    padding-left: 2.5rem;
  }
}
.lower_header_breadcrumbs .breadcrumbsLists_item:not(:first-child) a::before {
  content: "＞";
  position: absolute;
  top: 0.0625rem;
  left: 0.625rem;
}
@media screen and (min-width: 769px) {
  .lower_header_breadcrumbs .breadcrumbsLists_item:not(:first-child) a::before {
    left: 0.8125rem;
  }
}

.ly_belt {
  background-color: #FF0600;
  color: #ffffff;
  font-weight: 900;
  line-height: 1.4;
  text-align: center;
  margin: 0 calc(50% - 50vw);
  padding: 1.6875rem calc(50vw - 50%);
  font-feature-settings: "palt";
}
@media screen and (min-width: 769px) {
  .ly_belt {
    padding: 3.75rem calc(50vw - 50%);
  }
}
.ly_belt,
.ly_belt .ly_belt_lead {
  line-height: 1.6;
  letter-spacing: 0.05em;
  font-size: 1.25rem;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 769px) {
  .ly_belt,
  .ly_belt .ly_belt_lead {
    font-size: 1.875rem;
  }
}
.ly_belt .ly_belt-strong {
  display: inline-block;
  font-size: 2.125rem;
}
@media screen and (min-width: 769px) {
  .ly_belt .ly_belt-strong {
    font-size: 3.125rem;
  }
}
.ly_belt .ly_belt_lists {
  margin: 2.5rem auto 0;
  max-width: 48.125rem;
}
.ly_belt .ly_belt_lists-col2 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
}
@media screen and (min-width: 769px) {
  .ly_belt .ly_belt_lists-col2 {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.875rem;
  }
}

.course_container {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  padding: 2.5rem 0 5rem;
}
@media screen and (min-width: 769px) {
  .course_container {
    padding: 7.5rem 0;
  }
}

.course_text {
  font-size: 1rem;
  font-weight: bold;
  color: #DF0510;
}
@media screen and (min-width: 769px) {
  .course_text {
    font-size: 1.125rem;
  }
}

.course_strongText {
  font-size: 2.25rem;
  font-weight: bolder;
  line-height: 1.5;
  padding-top: 1.25rem;
  letter-spacing: -0.07em;
}
@media screen and (min-width: 769px) {
  .course_strongText {
    font-size: 3.125rem;
    letter-spacing: 0.03em;
  }
}
.course_strongText .red {
  color: #FF0600;
}
.course_strongText > span {
  display: inline-block;
}

.flag_img {
  width: 100%;
  max-width: 43.75rem;
  margin: 0 auto;
  padding-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .flag_img {
    padding-top: 3.125rem;
  }
}

.online_img {
  width: 100%;
  max-width: 32.875rem;
  margin: 0 auto;
  padding-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .online_img {
    padding-top: 3.75rem;
  }
}

.course_leadText {
  font-size: 1rem;
  font-weight: bold;
  line-height: 2;
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .course_leadText {
    font-size: 1.125rem;
    margin-top: 3.125rem;
  }
}

.modal_panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal_button {
  cursor: pointer;
}

.modal_panel.modal_panel-default {
  background-color: rgba(51, 51, 51, 0.7);
}

.modal_panel.is_active {
  opacity: 1;
  visibility: visible;
  z-index: 10000;
}

.modal_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
}

.modal_contents {
  position: relative;
}

.modal_contents.modal_contents-default {
  width: 80%;
  height: 80%;
  background-color: #fff;
}

.modal_close {
  cursor: pointer;
  position: absolute;
  top: -30px;
  right: 0;
}

.media_lv1 {
  background-color: #F7F5F5;
  margin: 0 calc(50% - 50vw);
  padding: 3.125rem 1.25rem;
}
@media screen and (min-width: 769px) {
  .media_lv1 {
    padding: 5rem 1.25rem;
  }
}
.media_lv1 .media_lv1_title {
  font-size: 1.875rem;
  font-weight: bold;
  letter-spacing: 0.03em;
  line-height: 1.5;
  text-align: center;
}
.media_lv1 .media_lv1_contents {
  max-width: 75rem;
  margin: 1.875rem auto 0;
}
@media screen and (min-width: 769px) {
  .media_lv1 .media_lv1_contents {
    margin: 3.75rem auto 0;
  }
}
@media screen and (min-width: 769px) and (min-width: 769px) {
  .media_lv1 .media_lv1_contents {
    display: grid;
    grid-template-columns: 18.75rem 1fr;
  }
}
.media_lv1 .media_lv1_profile {
  background-color: #FF0600;
  height: 5.1875rem;
  position: relative;
  border-radius: 0.625rem 0.625rem 0 0;
}
@media screen and (min-width: 769px) {
  .media_lv1 .media_lv1_profile {
    height: auto;
    border-radius: 0.625rem 0 0 0.625rem;
  }
}
.media_lv1 .media_lv1_profile_figure {
  position: absolute;
  top: 1.25rem;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (min-width: 769px) {
  .media_lv1 .media_lv1_profile_figure {
    position: static;
    top: auto;
    left: auto;
    transform: translateX(0);
    padding: 3.75rem 0;
  }
}
.media_lv1 .media_lv1_profile_figure_img {
  width: 7.0625rem;
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  .media_lv1 .media_lv1_profile_figure_img {
    width: 12.5rem;
  }
}
.media_lv1 .media_lv1_profile_figure_img img {
  border-radius: 62.4375rem;
}
.media_lv1 .media_lv1_profile_figcaption {
  font-size: 1.125rem;
  font-weight: bold;
  color: #FF0600;
  text-align: center;
  line-height: 1.3333333333;
}
@media screen and (min-width: 769px) {
  .media_lv1 .media_lv1_profile_figcaption {
    font-size: 1.875rem;
    color: #ffffff;
    margin-top: 0.625rem;
  }
}
.media_lv1 .media_lv1_box {
  background-color: #ffffff;
  padding: 6.25rem 1.25rem 1.5625rem;
  border-radius: 0 0 0.625rem 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 769px) {
  .media_lv1 .media_lv1_box {
    padding: 3.75rem 1.25rem;
    border-radius: 0 0.625rem 0.625rem 0;
  }
}
.media_lv1 .media_lv1_box_inner {
  flex: 1;
  max-width: 48.75rem;
}
.media_lv1 .media_lv1_box_title {
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1.625rem;
  font-weight: bold;
  letter-spacing: 0.03em;
  line-height: 1.4;
  padding-bottom: 1rem;
  border-bottom: solid 0.0625rem #FF0600;
  color: #FF0600;
}
@media screen and (min-width: 769px) {
  .media_lv1 .media_lv1_box_title {
    font-size: 1.875rem;
    padding-bottom: 2.5rem;
  }
}
.media_lv1 .media_lv1_box_text {
  margin-top: 1.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 2;
}
@media screen and (min-width: 769px) {
  .media_lv1 .media_lv1_box_text {
    font-size: 1rem;
    margin-top: 2.5rem;
  }
}
.media_lv1 .media_lv1_box_text p + p {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .media_lv1 .media_lv1_box_text p + p {
    margin-top: 2.5rem;
  }
}
.media_lv1 .media_lv1_box_text ul {
  padding-left: 1.5em;
  list-style-type: disc;
}

.kv {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem 0;
  grid-template-areas: "text" "splide" "button";
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .kv {
    margin-top: 0;
    grid-template-areas: "text splide" "button splide";
    grid-template-columns: 34.375vw 65.625vw;
  }
}
@media screen and (min-width: 1920px) {
  .kv {
    grid-template-columns: 41.25rem 78.75rem;
  }
}
.kv .kv_text {
  grid-area: text;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.kv .kv_text_img {
  position: relative;
  width: 100%;
  max-width: 19.75rem;
  aspect-ratio: 2.7719298246;
}
@media screen and (min-width: 769px) {
  .kv .kv_text_img {
    aspect-ratio: 3.2839506173;
    max-width: 80.6060606061%;
  }
}
@media screen and (min-width: 1920px) {
  .kv .kv_text_img {
    max-width: 33.25rem;
  }
}
.kv .kv_button {
  grid-area: button;
}
.kv .kv_button_link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 17.5rem;
  min-height: 3.625rem;
  background: #1E3CF2;
  padding: 0 3.3125rem;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 0.625rem;
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  .kv .kv_button_link {
    font-size: 1.0416666667vw;
    max-width: 19.2708333333vw;
    min-height: 3.90625vw;
    padding: 0 4.2708333333vw;
    font-size: 1.0416666667vw;
    border-radius: 0.5208333333vw;
  }
}
@media screen and (min-width: 1920px) {
  .kv .kv_button_link {
    max-width: 23.125rem;
    min-height: 4.6875rem;
    padding: 0 5.125rem;
    font-size: 1.25rem;
    border-radius: 0.625rem;
  }
}
.kv .kv_button_link .material-symbols-outlined {
  position: absolute;
  top: 50%;
  right: 1.0625rem;
  transform: translateY(-50%);
  color: currentColor;
  font-weight: 700;
  font-size: 1.5em;
}
@media screen and (min-width: 769px) {
  .kv .kv_button_link .material-symbols-outlined {
    right: 1.25vw;
  }
}
@media screen and (min-width: 1920px) {
  .kv .kv_button_link .material-symbols-outlined {
    right: 1.5rem;
  }
}
.kv .kv_splide {
  grid-area: splide;
}
.kv .kv_img {
  position: relative;
  width: 100%;
  aspect-ratio: 1.0533333333;
}
@media screen and (min-width: 769px) {
  .kv .kv_img {
    aspect-ratio: 1.3333333333;
  }
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #DA1725;
  padding: 1.875rem;
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .banner {
    margin-top: 0;
  }
}
.banner .banner_img {
  position: relative;
  width: 100%;
  max-width: 19.75rem;
  aspect-ratio: 1.356223176;
}
@media screen and (min-width: 769px) {
  .banner .banner_img {
    max-width: 41.6666666667vw;
    aspect-ratio: 4.4444444444;
  }
}
@media screen and (min-width: 1920px) {
  .banner .banner_img {
    max-width: 50rem;
  }
}

.voice {
  background: #F7F5F5;
  padding-top: 4.375rem;
  margin-top: 5rem;
}
@media screen and (min-width: 769px) {
  .voice {
    margin-top: 7.5rem;
  }
}
.voice .voice_title {
  position: relative;
  font-size: 0.875rem;
  font-weight: 700;
  width: 100%;
  max-width: 18.375rem;
  min-height: 3.4375rem;
  margin: 0 auto;
  background: #DA1725;
  color: #ffffff;
  border-radius: 0.625rem;
  text-align: center;
  line-height: 1.7142857143;
  padding: 0.4375rem 0;
}
@media screen and (min-width: 769px) {
  .voice .voice_title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9375vw;
    max-width: 41.6666666667vw;
    min-height: 2.8645833333vw;
    border-radius: 0.5208333333vw;
    padding: 0;
  }
}
@media screen and (min-width: 1920px) {
  .voice .voice_title {
    font-size: 1.125rem;
    max-width: 50rem;
    min-height: 3.4375rem;
    border-radius: 0.625rem;
  }
}
.voice .voice_title::before, .voice .voice_title::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  height: 0.0625rem;
  background: #DA1725;
  width: 1.25rem;
}
@media screen and (min-width: 769px) {
  .voice .voice_title::before, .voice .voice_title::after {
    width: 10.4166666667vw;
  }
}
@media screen and (min-width: 1920px) {
  .voice .voice_title::before, .voice .voice_title::after {
    width: 12.5rem;
  }
}
.voice .voice_title::before {
  left: 0;
  transform: translate(-100%, -50%);
}
.voice .voice_title::after {
  right: 0;
  transform: translate(100%, -50%);
}
.voice .voice_splide {
  padding: 4.0625rem 1.25rem 5.625rem;
}

.voiceSplide {
  min-height: 18.5rem;
}
.voiceSplide .splide__slide {
  background: #ffffff;
  border-radius: 0.625rem;
}
.voiceSplide .splide__pagination {
  bottom: -1.5rem;
  transform: translateY(50%);
}
@media screen and (min-width: 769px) {
  .voiceSplide .splide__pagination {
    bottom: -2.5rem;
  }
}

.aboutSec {
  padding: 4.375rem 0;
}
@media screen and (min-width: 769px) {
  .aboutSec {
    padding: 7.5rem 0 6rem;
  }
}
.aboutSec .aboutSec_inner {
  margin-top: 4.375rem;
}
@media screen and (min-width: 769px) {
  .aboutSec .aboutSec_inner {
    margin-top: 10rem;
  }
}
.aboutSec .aboutSec_inner_list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem;
}
@media screen and (min-width: 769px) {
  .aboutSec .aboutSec_inner_list {
    gap: 5rem;
  }
}

.dispatch {
  padding: 3.75rem 0;
  background: #F7F5F5;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 1000px) {
  .dispatch {
    padding: 7.5rem 0;
  }
}
@media screen and (min-width: 1000px) {
  .dispatch .dispatch_inner {
    margin: 0 1.25rem;
  }
}
.dispatch .dispatch_card {
  width: 100%;
  max-width: 37.5rem;
  background: url("../img/top/dispatch/dispatch_sp@2x.webp") no-repeat;
  background-size: 100% auto;
  margin: 0 auto;
  padding: 11.875rem 1.25rem 0;
}
@media screen and (min-width: 1000px) {
  .dispatch .dispatch_card {
    padding: 24.7074122237vw 1.25rem 0;
  }
}
@media screen and (min-width: 1000px) {
  .dispatch .dispatch_card {
    background: url("../img/top/dispatch/dispatch@2x.webp") no-repeat;
    background-size: 63.9534883721% auto;
    padding: 2.5rem 0;
    margin: 0;
    max-width: 100%;
  }
}
.dispatch .dispatch_card .dispatch_card_content {
  padding: 1.875rem 1.5625rem;
  background: #ffffff;
  border-radius: 0.625rem;
  width: 100%;
}
@media screen and (min-width: 1000px) {
  .dispatch .dispatch_card .dispatch_card_content {
    padding: 3.125rem 3.75rem;
    max-width: 45.3488372093%;
    margin-left: auto;
  }
}
.dispatch .dispatch_card .dispatch_card_title {
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
  font-weight: 700;
}
.dispatch .dispatch_card .dispatch_card_title_main {
  font-size: 0.875rem;
  color: #DA1725;
}
@media screen and (min-width: 1000px) {
  .dispatch .dispatch_card .dispatch_card_title_main {
    font-size: 0.9375vw;
  }
}
@media screen and (min-width: 1920px) {
  .dispatch .dispatch_card .dispatch_card_title_main {
    font-size: 1.125rem;
  }
}
.dispatch .dispatch_card .dispatch_card_title_sub {
  font-size: 1.5rem;
}
@media screen and (min-width: 1000px) {
  .dispatch .dispatch_card .dispatch_card_title_sub {
    font-size: 1.7708333333vw;
  }
}
@media screen and (min-width: 1920px) {
  .dispatch .dispatch_card .dispatch_card_title_sub {
    font-size: 2.125rem;
  }
}
.dispatch .dispatch_card .dispatch_card_content_body {
  margin-top: 0.875rem;
  padding-top: 1.25rem;
  border-top: 0.0625rem solid #DA1725;
}
@media screen and (min-width: 1000px) {
  .dispatch .dispatch_card .dispatch_card_content_body {
    margin-top: 1.875rem;
    padding-top: 1.875rem;
  }
}
.dispatch .dispatch_card .dispatch_card_description {
  font-size: 1rem;
  font-weight: 500;
}
@media screen and (min-width: 1920px) {
  .dispatch .dispatch_card .dispatch_card_description {
    font-size: 1rem;
  }
}
.dispatch .dispatch_card .dispatch_card_content_footer {
  margin-top: 1.875rem;
}
@media screen and (min-width: 1000px) {
  .dispatch .dispatch_card .dispatch_card_content_footer {
    margin-top: 3.75rem;
  }
}

.campaign {
  padding: 4.375rem 0;
}
@media screen and (min-width: 769px) {
  .campaign {
    padding: 7.5rem 0;
  }
}
.insta {
  background: #ffffff;
  padding: 4.375rem 0;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding-left: calc(50vw - 50%);
  padding-right: calc(50vw - 50%);
}
@media screen and (min-width: 769px) {
  .insta {
    padding: 7.5rem 0;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    padding-left: calc(50vw - 50%);
    padding-right: calc(50vw - 50%);
  }
}
.insta .insta_list {
  display: grid;
  gap: 0.3125rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .insta .insta_list {
    margin-top: 3.75rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
.insta .insta_link {
  position: relative;
  aspect-ratio: 1;
  overflow: hidden;
}
.insta .insta_link img,
.insta .insta_link video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: initial;
  height: 100%;
}
.insta .insta_inner_button {
  max-width: 31.25rem;
  margin: 0 auto;
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .insta .insta_inner_button {
    margin-top: 3.75rem;
  }
}

.about .about_lead {
  padding-top: 2.5rem;
  padding-bottom: 5rem;
}
@media screen and (min-width: 769px) {
  .about .about_lead {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}
.about .about_lead_title {
  font-size: 1rem;
  font-weight: bold;
  color: #FF0600;
}
@media screen and (min-width: 769px) {
  .about .about_lead_title {
    font-size: 1.125rem;
  }
}
.about .about_lead_box {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .about .about_lead_box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
.about .about_lead_box p:last-child {
  margin-top: 2.5rem;
}
.about .about_lead_box_text {
  font-size: 1rem;
  font-weight: bold;
  line-height: 2;
}
@media screen and (min-width: 769px) {
  .about .about_lead_box_text {
    flex: 1;
    padding-right: 3.125rem;
  }
}
.about .about_lead_box_img {
  margin: 2.5rem auto 0;
  width: 100%;
  max-width: 21.25rem;
}
@media screen and (min-width: 769px) {
  .about .about_lead_box_img {
    margin: 0;
  }
}
.about .about_act {
  padding-top: 5rem;
}
@media screen and (min-width: 769px) {
  .about .about_act {
    padding-top: 7.5rem;
  }
}

.adult .adult_course {
  padding-bottom: 5rem;
}
@media screen and (min-width: 769px) {
  .adult .adult_course {
    padding-bottom: 7.5rem;
  }
}

.faq .container {
  padding-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .faq .container {
    padding-top: 7.5rem;
  }
}
.faq .faq_wrap_title {
  font-size: 1rem;
  font-weight: bold;
  color: #FF0600;
}
@media screen and (min-width: 769px) {
  .faq .faq_wrap_title {
    font-size: 1.125rem;
  }
}
.faq .faq_lists {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .faq .faq_lists {
    margin-top: 2.5rem;
  }
}
.faq .faq_lists_item dt {
  font-size: 1.375rem;
  font-weight: bold;
  padding-bottom: 0.625rem;
  border-bottom: solid 0.0625rem #FF0600;
  position: relative;
  padding-left: 1.875rem;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 769px) {
  .faq .faq_lists_item dt {
    font-size: 1.875rem;
    padding-left: 2.5rem;
    padding-bottom: 1rem;
  }
}
.faq .faq_lists_item dt::before {
  content: "Q.";
  color: #003CF2;
  position: absolute;
  top: 0;
  left: 0;
}
.faq .faq_lists_item dd {
  margin-top: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.75;
  padding-left: 0.625rem;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 769px) {
  .faq .faq_lists_item dd {
    font-size: 1.125rem;
    margin-top: 1.25rem;
    line-height: 2;
  }
}
.faq .faq_lists_item + .faq_lists_item {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .faq .faq_lists_item + .faq_lists_item {
    margin-top: 3.125rem;
  }
}

.multilingual .anchor_box {
  background-color: #FF0600;
  padding: 1.875rem 0;
}
@media screen and (min-width: 769px) {
  .multilingual .anchor_box {
    padding: 3.75rem 0;
  }
}
.multilingual .anchor_list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
@media screen and (min-width: 769px) {
  .multilingual .anchor_list {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem;
  }
}
.multilingual .anchor_list .button_lv1__text {
  font-weight: bold;
}
.multilingual .lang_content {
  padding-top: 5rem;
}
@media screen and (min-width: 769px) {
  .multilingual .lang_content {
    padding-top: 7.5rem;
  }
}
.multilingual .lang_inner {
  padding-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .multilingual .lang_inner {
    display: flex;
    align-items: start;
    gap: 3.75rem;
    padding-top: 3.75rem;
  }
}
.multilingual .lang_text {
  width: 100%;
}
.multilingual .lang_text .catch {
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.5;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 769px) {
  .multilingual .lang_text .catch {
    font-size: 1.875rem;
  }
}
.multilingual .lang_text .catch .red {
  color: #FF0600;
}
.multilingual .lang_text .catch > span {
  display: inline-block;
}
.multilingual .lang_text .text {
  font-size: 1rem;
  padding-top: 1.875rem;
  padding-left: 0.625rem;
}
@media screen and (min-width: 769px) {
  .multilingual .lang_text .text {
    padding-left: 0;
  }
}
.multilingual .lang_text .speak_content {
  padding-top: 1.875rem;
}
.multilingual .lang_text .speak_inner {
  padding: 0.875rem 1.625rem;
  background-color: #F7F5F5;
  border-radius: 0.625rem;
}
.multilingual .lang_text .speak_text {
  position: relative;
  display: block;
  font-weight: bold;
  font-size: 1rem;
  padding-left: 1.5rem;
}
.multilingual .lang_text .speak_text::before {
  content: "";
  position: absolute;
  top: 0.8em;
  left: 0;
  width: 1rem;
  height: 1.125rem;
  background-image: url("../svg/icons/check.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.multilingual .lang_img {
  width: 100%;
  padding-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .multilingual .lang_img {
    padding-top: 0;
    max-width: 27.5rem;
  }
}
.multilingual .price_button {
  width: 100%;
  margin: 0 auto;
  padding-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .multilingual .price_button {
    padding-top: 3.75rem;
    max-width: 31.25rem;
  }
}

.lower.dispatch {
  background-color: #ffffff;
  padding-bottom: 0;
}
.lower.dispatch .dispatch_section_inner {
  padding-top: 5rem;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .dispatch_section_inner {
    padding-top: 7.5rem;
  }
}
.lower.dispatch .dispatch_lead {
  margin-top: 1.875rem;
  font-weight: bold;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .dispatch_lead {
    margin-top: 2.5rem;
  }
}
.lower.dispatch .merit {
  margin-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .merit {
    margin-top: 5rem;
  }
}
.lower.dispatch .merit_title {
  font-size: 1.625rem;
  font-weight: bold;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .merit_title {
    font-size: 1.875rem;
  }
}
.lower.dispatch .merit_lists {
  margin-top: 1.875rem;
  background-color: #F7F5F5;
  border-radius: 0.625rem;
  padding: 1.875rem 1.25rem;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .merit_lists {
    padding: 1.875rem 3.125rem;
  }
}
.lower.dispatch .merit_lists_itme dl {
  position: relative;
  padding-left: 2.3125rem;
}
.lower.dispatch .merit_lists_itme dl::before {
  content: "";
  display: block;
  position: absolute;
  top: 0.75rem;
  left: 0;
  width: 1.5rem;
  height: 1.0625rem;
  background-image: url("../svg/icons/check02.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .merit_lists_itme dl::before {
    top: 1.125rem;
  }
}
.lower.dispatch .merit_lists_itme dt {
  font-size: 1.25rem;
  font-weight: bold;
  color: #003CF2;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .merit_lists_itme dt {
    font-size: 1.5rem;
  }
}
.lower.dispatch .merit_lists_itme dd {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.25rem;
  line-height: 1.5;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .merit_lists_itme dd {
    font-size: 1.25rem;
  }
}
.lower.dispatch .merit_lists_itme + .merit_lists_itme {
  margin-top: 1.25rem;
}
.lower.dispatch .achievement {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem;
  align-items: flex-start;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .achievement {
    margin-top: 5rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
  }
}
@media screen and (min-width: 769px) {
  .lower.dispatch .achievement_detail {
    order: 2;
  }
}
.lower.dispatch .achievement_img {
  max-width: 27.5rem;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .achievement_img {
    margin-right: 1.25rem;
    order: 1;
  }
}
.lower.dispatch .achievement_img img {
  border-radius: 0.625rem;
}
.lower.dispatch .achievement_detail_title {
  font-size: 1.625rem;
  font-weight: bold;
  letter-spacing: 0.08em;
  line-height: 1.6;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .achievement_detail_title {
    font-size: 1.875rem;
    letter-spacing: 0.03em;
  }
}
.lower.dispatch .achievement_detail_title > span {
  display: inline-block;
}
.lower.dispatch .achievement_detail_box {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .achievement_detail_box {
    padding-left: 1.125rem;
  }
}
.lower.dispatch .achievement_detail_box .detail_text {
  font-weight: 500;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .accordion {
    margin-top: 3.125rem;
  }
}
.lower.dispatch .accordion summary {
  list-style: none;
  cursor: pointer;
  background-color: #003CF2;
  border-radius: 0.625rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  padding: 0.75rem;
  position: relative;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .accordion summary {
    font-size: 1.25rem;
    padding: 1rem;
  }
}
.lower.dispatch .accordion summary::before {
  content: "";
  display: block;
  width: 1.0625rem;
  height: 1.0625rem;
  background-image: url("../svg/icons/plus.svg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  right: 1.25rem;
  transition: transform 0.5s ease;
  transform: translateY(-50%);
}
.lower.dispatch .accordion .accordion_inner {
  background-color: #F7F5F5;
  border-radius: 0 0 0.625rem 0.625rem;
  padding: 1.5625rem 0.625rem 2.1875rem;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .accordion .accordion_inner {
    padding: 1.5625rem 3.125rem 2.1875rem;
  }
}
.lower.dispatch .accordion[open] summary {
  border-radius: 0.625rem 0.625rem 0 0;
}
.lower.dispatch .accordion[open] summary::before {
  transform: translateY(-50%) rotate(135deg);
}
.lower.dispatch .text-red {
  color: #FF0600;
}
.lower.dispatch .dispatchSplide_wrap {
  margin-top: 5rem;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .dispatchSplide_wrap {
    margin-top: 8.125rem;
  }
}
.lower.dispatch .btnLists {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .btnLists {
    margin-top: 1.5625rem;
  }
}
.lower.dispatch .btnLists li + li {
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .lower.dispatch .btnLists li + li {
    margin-top: 1.5625rem;
  }
}

.classroom .anchor_box {
  background-color: #F7F5F5;
  padding: 3.125rem 0;
}
@media screen and (min-width: 769px) {
  .classroom .anchor_box {
    padding: 3.75rem 0;
  }
}
.classroom .anchor_list {
  display: grid;
  gap: 2.5rem;
}
@media screen and (min-width: 769px) {
  .classroom .anchor_list {
    grid-template-columns: repeat(2, 1fr);
    gap: 3.75rem;
  }
}
.classroom .anchor_list .button_lv1 {
  padding: 0.625rem 0.9375rem;
}
@media screen and (min-width: 769px) {
  .classroom .anchor_list .button_lv1 {
    padding: 0.625rem 1.5625rem;
  }
}
.classroom .anchor_item .map {
  width: 100%;
  aspect-ratio: 1.5272727273;
  line-height: 1;
}
@media screen and (min-width: 769px) {
  .classroom .anchor_item .map {
    aspect-ratio: 2.1363636364;
  }
}
.classroom .anchor_item .button {
  padding-top: 1.875rem;
}
.classroom .anchor_item .address {
  font-size: 1rem;
  font-weight: bold;
  padding-top: 1.25rem;
  padding-left: 0.625rem;
}
@media screen and (min-width: 769px) {
  .classroom .anchor_item .address {
    font-size: 1.125rem;
    padding-left: 1.5625rem;
  }
}
.classroom .class_content {
  padding-top: 5rem;
}
@media screen and (min-width: 769px) {
  .classroom .class_content {
    padding-top: 7.5rem;
  }
}
.classroom .class_catch {
  font-size: 1.625rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.03em;
  padding-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .classroom .class_catch {
    font-size: 1.875rem;
    padding-top: 3.75rem;
  }
}
.classroom .class_catch .red {
  color: #FF0600;
}
.classroom .class_catch > span {
  display: inline-block;
}
.classroom .class_text {
  font-size: 1rem;
  padding-top: 1.875rem;
}
.classroom .classImg_list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.9375rem;
  padding-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .classroom .classImg_list {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem;
    padding-top: 3.75rem;
  }
}
.classroom .classImg_item {
  border-radius: 0.625rem;
}
.classroom .class_campaign {
  display: grid;
  gap: 1.25rem;
  margin-top: 2.5rem;
  padding: 0.9375rem 1.25rem;
  background-color: #F7F5F5;
  border-radius: 0.625rem;
}
@media screen and (min-width: 769px) {
  .classroom .class_campaign {
    padding: 0.9375rem 3.75rem;
    margin-top: 3.75rem;
  }
}
.classroom .class_campaign .campaign_title {
  font-family: "tekton-pro-condensed", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #FF0600;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px) {
  .classroom .class_campaign .campaign_title {
    font-size: 1.125rem;
  }
}
.classroom .class_campaign .campaign_text {
  display: block;
  font-weight: bold;
  font-size: 1rem;
}
@media screen and (min-width: 769px) {
  .classroom .class_campaign .campaign_text {
    padding-left: 1.25rem;
  }
}
.classroom .class_campaign .campaign_text.outside {
  color: #939393;
  padding-left: 0;
}
.classroom .class_inner {
  display: grid;
  gap: 1.875rem;
  padding-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .classroom .class_inner {
    grid-template-columns: repeat(2, 1fr);
    gap: 4.375rem;
    justify-content: space-between;
    padding-top: 3.75rem;
  }
}
@media screen and (min-width: 769px) {
  .classroom .class_inner .class_request {
    padding-right: 1.5625rem;
  }
}
.classroom .class_inner .map {
  width: 100%;
  aspect-ratio: 1.5067264574;
  line-height: 1;
}
@media screen and (min-width: 769px) {
  .classroom .class_inner .map {
    aspect-ratio: 0.88;
  }
}
.classroom .class_inner .map iframe {
  border-radius: 0.625rem;
}
.classroom .class_inner .request_button {
  padding-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .classroom .class_inner .request_button {
    padding-top: 2.5rem;
  }
}
.classroom .class_inner .access_title {
  font-size: 1.125rem;
  font-weight: bold;
  color: #FF0600;
  line-height: 1.5;
}
.classroom .class_inner .class_accessItem {
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 0.0625rem solid #FF0600;
}
@media screen and (min-width: 769px) {
  .classroom .class_inner .class_accessItem {
    padding-top: 1.875rem;
  }
}
.classroom .class_inner .class_accessItem:first-of-type {
  padding-top: 0;
}
.classroom .class_inner .access_text {
  font-size: 1rem;
  padding-top: 0.3125rem;
  padding-left: 1.25rem;
}
.classroom .class_inner .access_text .attention {
  font-size: 0.875rem;
}

.kids .kids_main {
  padding: 2rem 0 5rem;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 769px) {
  .kids .kids_main {
    padding: 0 1.25rem 7.5rem;
  }
}
.kids .kids_main_img {
  position: relative;
  width: 100%;
  max-width: 31.25rem;
  aspect-ratio: 0.5987261146;
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  .kids .kids_main_img {
    max-width: 109.8125rem;
    aspect-ratio: 1.8340292276;
  }
}
.kids .kids_voice {
  margin-top: 5rem;
  background: #F7F5F5;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding-left: calc(50vw - 50%);
  padding-right: calc(50vw - 50%);
}
@media screen and (min-width: 769px) {
  .kids .kids_voice {
    margin-top: 7.5rem;
  }
}
.kids .kids_lesson {
  background: #F7F5F5;
  margin-top: 3.75rem;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 769px) {
  .kids .kids_lesson {
    margin-top: 7.5rem;
  }
}
.kids .kids_lesson_inner {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5rem;
  padding: 1.875rem 1.25rem;
}
@media screen and (min-width: 1000px) {
  .kids .kids_lesson_inner {
    grid-template-columns: repeat(2, 1fr);
    gap: 4.1666666667vw;
    padding: 5.9375rem 5.2083333333vw 6.4375rem;
  }
}
@media screen and (min-width: 1920px) {
  .kids .kids_lesson_inner {
    gap: 5rem;
    padding: 5.9375rem 6.25rem 6.4375rem;
  }
}
.kids .kids_lesson_inner .kids_lesson_title {
  font-size: 1.125rem;
  font-weight: 700;
  padding-left: 0.625rem;
  line-height: 1.3333333333;
}
@media screen and (min-width: 1000px) {
  .kids .kids_lesson_inner .kids_lesson_title {
    padding-left: 0;
    font-size: 1.25vw;
  }
}
@media screen and (min-width: 1920px) {
  .kids .kids_lesson_inner .kids_lesson_title {
    font-size: 1.5rem;
  }
}
.kids .kids_lesson_inner .kids_lesson_title span {
  display: block;
  color: #DA1725;
}
@media screen and (min-width: 1000px) {
  .kids .kids_lesson_inner .kids_lesson_title span {
    display: inline;
  }
}
.kids .kids_lesson_inner .kids_lesson_movie {
  position: relative;
  width: 100%;
  aspect-ratio: 2.78125;
  background: #939393;
  margin-top: 0.3125rem;
}
.kids .kids_course .kids_course_title {
  max-width: 62.5rem;
  margin: 0 auto;
}
.kids .kids_course .kids_course_list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  width: 100%;
  max-width: 72.375rem;
  margin: 0 auto;
  margin-top: 1.875rem;
}
@media screen and (min-width: 1000px) {
  .kids .kids_course .kids_course_list {
    margin-top: 4.125rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.125rem;
  }
}
.kids .kids_course .kids_course_list .kids_course_item {
  border: 0.0625rem solid #FF0600;
  border-bottom: 0.625rem solid #1E3CF2;
  border-radius: 0.625rem;
  padding: 1.25rem 0.625rem;
}
@media screen and (min-width: 769px) {
  .kids .kids_course .kids_course_list .kids_course_item {
    padding: 1.875rem 1.875rem 2.5rem;
  }
}
.kids .kids_course .kids_course_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.kids .kids_course .kids_course_card .kids_course_card_number {
  display: flex;
  gap: 0.5rem;
  color: #FF0600;
  font-size: 1rem;
  font-weight: 700;
}
@media screen and (min-width: 769px) {
  .kids .kids_course .kids_course_card .kids_course_card_number {
    font-size: 1.25rem;
  }
}
.kids .kids_course .kids_course_card .kids_course_card_heading {
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  background: #FF0600;
  width: 100%;
  border-radius: 0.625rem;
  padding: 0.875rem 0.5625rem;
  margin-top: 0.625rem;
  line-height: 1.4;
}
@media screen and (min-width: 769px) {
  .kids .kids_course .kids_course_card .kids_course_card_heading {
    padding: 1rem 1.25rem 1.25rem;
    font-size: 1.875rem;
  }
}
.kids .kids_course .kids_course_card .kids_course_card_img {
  width: 100%;
  max-width: 18.5rem;
  aspect-ratio: 1.7307692308;
  position: relative;
  border-radius: 0.625rem;
  overflow: hidden;
  margin-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .kids .kids_course .kids_course_card .kids_course_card_img {
    max-width: 28.125rem;
    margin-top: 2.5rem;
  }
}
.kids .kids_course .kids_course_card .kids_course_card_description {
  margin: 0 auto;
  margin-top: 0.9375rem;
  max-width: 18.5rem;
}
@media screen and (min-width: 769px) {
  .kids .kids_course .kids_course_card .kids_course_card_description {
    max-width: 28.125rem;
    margin-top: 0.625rem;
  }
}
.kids .kids_course .kids_course_card .kids_course_card_description > p {
  font-size: 1rem;
  font-weight: 500;
}
.kids .kids_movie {
  padding: 5rem 0 6.25rem;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding-left: calc(50vw - 50%);
  padding-right: calc(50vw - 50%);
}
.kids .kids_movie .kids_movie_title {
  font-size: 1.125rem;
  font-weight: 700;
}
@media screen and (min-width: 769px) {
  .kids .kids_movie .kids_movie_title {
    font-size: 2.125rem;
  }
}
.kids .kids_movie .kids_movie_inner {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem 5rem;
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .kids .kids_movie .kids_movie_inner {
    margin-top: 3.75rem;
    grid-template-columns: repeat(2, 1fr);
  }
}
.kids .kids_movie .kids_movie_movie {
  position: relative;
  width: 100%;
  aspect-ratio: 1.3333333333;
  background: #939393;
}
.kids .kids_introduction {
  padding-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .kids .kids_introduction {
    padding-top: 7.5rem;
  }
}
.kids .kids_introduction .kids_introduction_list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem;
}
@media screen and (min-width: 769px) {
  .kids .kids_introduction .kids_introduction_list {
    gap: 5rem;
  }
}

.eiken .eiken_voice {
  background: #F7F5F5;
  padding-bottom: 5rem;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding-left: calc(50vw - 50%);
  padding-right: calc(50vw - 50%);
}
.eiken .eiken_voice .eiken_voice_title {
  font-size: 1.875rem;
  font-weight: 700;
  text-align: center;
}
.eiken .eiken_merit {
  padding-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .eiken .eiken_merit {
    padding-top: 7.5rem;
  }
}
.eiken .eiken_reason {
  margin-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .eiken .eiken_reason {
    margin-top: 7.5rem;
  }
}
.eiken .eiken_reason .eiken_reason_inner {
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .eiken .eiken_reason .eiken_reason_inner {
    margin-top: 5rem;
  }
}
.eiken .eiken_reason .eiken_reason_list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem;
}
@media screen and (min-width: 769px) {
  .eiken .eiken_reason .eiken_reason_list {
    gap: 5rem;
  }
}
.eiken .eiken_reason .eiken_reason_list .eiken_reason_list_item:not(:first-child) {
  border-top: 0.0625rem solid #FF0600;
  padding-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .eiken .eiken_reason .eiken_reason_list .eiken_reason_list_item:not(:first-child) {
    padding-top: 5rem;
  }
}
.eiken .eiken_splide {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding: 5rem 0;
}
@media screen and (min-width: 769px) {
  .eiken .eiken_splide {
    padding: 10rem 0;
  }
}
.eiken .eiken_lesson {
  padding: 3.75rem 0;
  background: #F7F5F5;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding-left: calc(50vw - 50%);
  padding-right: calc(50vw - 50%);
}
@media screen and (min-width: 769px) {
  .eiken .eiken_lesson {
    padding: 7.5rem 0;
    padding-left: calc(50vw - 50%);
    padding-right: calc(50vw - 50%);
  }
}
.eiken .eiken_lesson .eiken_lesson_subText {
  margin-top: 2.5rem;
  font-size: 1.125rem;
}
.eiken .eiken_lesson .eiken_lesson_section {
  margin-top: 3.75rem;
}
.lesson_list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
}
@media screen and (min-width: 769px) {
  .lesson_list {
    gap: 3.75rem;
  }
}
.lesson_list .lesson_header {
  background: #FF0600;
  color: #ffffff;
  display: flex;
  align-items: center;
  font-weight: 700;
  border-radius: 0.625rem;
  min-height: 3.75rem;
  gap: 0.625rem;
  padding: 0 0.9375rem;
}
@media screen and (min-width: 769px) {
  .lesson_list .lesson_header {
    min-height: 5rem;
    gap: 1.25rem;
    padding: 0 1.875rem;
  }
}
.lesson_list .lesson_number {
  font-size: 0.875rem;
}
@media screen and (min-width: 769px) {
  .lesson_list .lesson_number {
    font-size: 1.125rem;
  }
}
.lesson_list .lesson_title {
  font-size: 1.25rem;
}
@media screen and (min-width: 769px) {
  .lesson_list .lesson_title {
    font-size: 1.875rem;
  }
}
.lesson_list .lesson_description {
  margin-top: 1rem;
  padding-left: 1rem;
}
@media screen and (min-width: 769px) {
  .lesson_list .lesson_description {
    margin-top: 1.875rem;
    padding-left: 1.875rem;
  }
}

.levels_section {
  padding-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .levels_section {
    padding-top: 5rem;
  }
}
.levels_section .levels_list {
  border-radius: 0.625rem;
  border: 0.0625rem solid #CECECE;
  overflow: hidden;
}
.levels_section .levels_list .level_item {
  display: grid;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 769px) {
  .levels_section .levels_list .level_item {
    grid-template-columns: 7.0833333333vw 18.9583333333vw 26.0416666667vw;
  }
}
@media screen and (min-width: 1000px) {
  .levels_section .levels_list .level_item {
    grid-template-columns: 8.5rem 22.75rem 31.25rem;
  }
}
.levels_section .levels_list .level_item_head {
  display: none;
  background: #FF0600;
}
@media screen and (min-width: 769px) {
  .levels_section .levels_list .level_item_head {
    display: grid;
  }
}
.levels_section .levels_list .level_item_head .level_degree,
.levels_section .levels_list .level_item_head .level_benefit {
  font-size: 1.125rem;
  font-weight: 700;
  color: #ffffff;
  border-left: 0.0625rem solid #ffffff;
}
@media screen and (min-width: 769px) {
  .levels_section .levels_list .level_item_body:nth-child(even) {
    background: #ffffff;
  }
  .levels_section .levels_list .level_item_body:nth-child(odd) {
    background: #F7F5F5;
  }
}
@media screen and (max-width: 768px) {
  .levels_section .levels_list .level_item_body .level_title {
    font-size: 1.125rem;
    font-weight: 700;
    color: #ffffff;
    background: #FF0600;
  }
}
.levels_section .levels_list .level_title,
.levels_section .levels_list .level_degree,
.levels_section .levels_list .level_benefit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}
.levels_section .levels_list .level_degree,
.levels_section .levels_list .level_benefit {
  border-left: 0.0625rem solid #CECECE;
}
.levels_section .levels_list .level_degree > span,
.levels_section .levels_list .level_benefit > span {
  display: inline;
}
@media screen and (min-width: 769px) {
  .levels_section .levels_list .level_degree > span,
  .levels_section .levels_list .level_benefit > span {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .levels_section .levels_list .level_benefit {
    flex-direction: column;
    text-align: center;
    background: #F7F5F5;
  }
}
.levels_section .levels_note {
  margin-top: 1.25rem;
  position: relative;
  font-size: 1rem;
  padding-left: 1.5em;
}
.levels_section .levels_note > span {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0);
}
.levels_section .levels_link {
  display: inline-block;
  font-weight: 500;
  color: #FF0600;
}

.support_section .support_number {
  font-size: 1rem;
  font-weight: 700;
  color: #FF0600;
}
.support_section .support_title {
  font-size: 1.625rem;
  font-weight: 700;
}
@media screen and (min-width: 769px) {
  .support_section .support_title {
    font-size: 1.875rem;
  }
}
.support_section .support_title > span {
  color: #FF0600;
}
.support_section .support_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  margin-top: 1.875rem;
}
@media screen and (min-width: 1000px) {
  .support_section .support_wrapper {
    gap: 2.375rem;
  }
}
@media screen and (min-width: 1000px) {
  .support_section .support_wrapper._col2 {
    grid-template-columns: 1fr 23.75rem;
  }
}
.support_section .support_content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
}
.support_section .support_text {
  position: relative;
  padding-left: 2em;
  font-size: 1rem;
  font-weight: 500;
}
.support_section .support_text .material-symbols-outlined {
  position: absolute;
  top: 0.2em;
  left: 0;
  color: #FF0600;
}
.support_section .support_note {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 0.75rem;
}
.support_section .support_conclusion {
  font-size: 1rem;
  font-weight: 700;
}
.support_section .support_image {
  position: relative;
  width: 100%;
  aspect-ratio: 0.9882352941;
}
@media screen and (min-width: 769px) {
  .support_section .support_image {
    aspect-ratio: 1;
  }
}
.lecturer .lecturer_header {
  padding-top: 5rem;
}
@media screen and (min-width: 769px) {
  .lecturer .lecturer_header {
    padding-top: 7.5rem;
  }
}
.lecturer .prelusion_inner {
  padding-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .lecturer .prelusion_inner {
    padding-top: 7.5rem;
  }
}
.lecturer .prelusion_text {
  margin-top: 1.875rem;
  font-size: 1rem;
  font-weight: bold;
}
@media screen and (min-width: 769px) {
  .lecturer .prelusion_text {
    margin-top: 2.5rem;
    font-size: 1.125rem;
  }
}
.lecturer .prelusion {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  padding-bottom: 3.75rem;
  padding-right: calc(50vw - 50%);
  padding-left: calc(50vw - 50%);
  background-color: #F7F5F5;
}
@media screen and (min-width: 769px) {
  .lecturer .prelusion {
    padding-bottom: 7.5rem;
  }
}
.lecturer .prelusion .prelusion_lists {
  display: flex;
  flex-wrap: wrap;
  margin-top: -2.5rem;
  margin-left: -1.25rem;
}
@media screen and (min-width: 600px) {
  .lecturer .prelusion .prelusion_lists {
    margin-left: -2.5rem;
  }
}
@media screen and (min-width: 1000px) {
  .lecturer .prelusion .prelusion_lists {
    margin-top: -3.75rem;
    margin-left: -3.125rem;
  }
}
.lecturer .prelusion .prelusion_lists_item {
  width: 50%;
  padding-top: 2.5rem;
  padding-left: 1.25rem;
}
@media screen and (min-width: 600px) {
  .lecturer .prelusion .prelusion_lists_item {
    width: 33.33333%;
    padding-left: 2.5rem;
  }
}
@media screen and (min-width: 769px) {
  .lecturer .prelusion .prelusion_lists_item {
    width: 25%;
  }
}
@media screen and (min-width: 1000px) {
  .lecturer .prelusion .prelusion_lists_item {
    padding-top: 3.75rem;
    padding-left: 3.125rem;
  }
}
.lecturer .prelusion .person_figure_img {
  position: relative;
}
.lecturer .prelusion .person_figure_img,
.lecturer .prelusion .person_figure_img .default_img,
.lecturer .prelusion .person_figure_img .hover_img,
.lecturer .prelusion .person_figure_img picture,
.lecturer .prelusion .person_figure_img img {
  border-radius: 62.4375rem;
}
.lecturer .prelusion .person_figure_img .hover_normal {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.lecturer .prelusion .person_figure_img .hover_normal img {
  transition: transform 0.2s linear;
}
.lecturer .prelusion .person_figure_img .hover_normal:hover img {
  transform: scale(1.1);
}
.lecturer .prelusion .person_figure_img .hover_action {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s linear;
}
.lecturer .prelusion .person_figure_img .hover_action img {
  object-fit: cover;
}
.lecturer .prelusion .person_figure_img .hover_action:hover {
  opacity: 0;
}
.lecturer .prelusion .person_figcaption .name,
.lecturer .prelusion .person_figcaption .name_ja {
  display: block;
  text-align: center;
  line-height: 1.1;
}
.lecturer .prelusion .person_figcaption .name {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 0.625rem;
}
@media screen and (min-width: 769px) {
  .lecturer .prelusion .person_figcaption .name {
    font-size: 1.75rem;
    margin-top: 1.25rem;
  }
}
.lecturer .prelusion .person_figcaption .name_ja {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.3125rem;
}
@media screen and (min-width: 769px) {
  .lecturer .prelusion .person_figcaption .name_ja {
    font-size: 1.125rem;
    margin-top: 0.625rem;
  }
}
.lecturer .modal_contents {
  border-radius: 0.625rem;
  background-color: #ffffff;
  width: 100%;
  height: 90%;
}
@media screen and (min-width: 769px) {
  .lecturer .modal_contents {
    height: 80%;
  }
}
@media screen and (min-width: 1000px) {
  .lecturer .modal_contents {
    width: 80%;
  }
}
.lecturer .modal_contents_inner {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-radius: 0.625rem;
}
@media screen and (min-width: 769px) {
  .lecturer .modal_contents_inner {
    display: grid;
    grid-template-columns: 18.75rem 1fr;
  }
}
.lecturer .modal_panel .profile {
  color: #ffffff;
  background-color: #FF0600;
  padding: 2.5rem 1.25rem;
  position: relative;
}
@media screen and (min-width: 769px) {
  .lecturer .modal_panel .profile {
    padding: 4.5rem 1.25rem;
  }
}
.lecturer .modal_panel .profile .profile_figure {
  max-width: 7.5rem;
  margin: 0 auto;
  position: absolute;
  top: 1.25rem;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (min-width: 769px) {
  .lecturer .modal_panel .profile .profile_figure {
    max-width: 12.5rem;
    position: sticky;
    bottom: auto;
    top: 4.5rem;
    left: 0;
    transform: translateX(0);
  }
}
.lecturer .modal_panel .profile .profile_figure img {
  border-radius: 62.4375rem;
}
.lecturer .modal_panel .profile .profile_figcaption .name,
.lecturer .modal_panel .profile .profile_figcaption .name_ja {
  display: block;
  text-align: center;
  color: #FF0600;
}
@media screen and (min-width: 769px) {
  .lecturer .modal_panel .profile .profile_figcaption .name,
  .lecturer .modal_panel .profile .profile_figcaption .name_ja {
    color: #ffffff;
  }
}
.lecturer .modal_panel .profile .profile_figcaption .name {
  font-size: 1.375rem;
  font-weight: bold;
}
@media screen and (min-width: 769px) {
  .lecturer .modal_panel .profile .profile_figcaption .name {
    font-size: 1.875rem;
  }
}
.lecturer .modal_panel .profile .profile_figcaption .name_ja {
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: -0.25rem;
}
@media screen and (min-width: 769px) {
  .lecturer .modal_panel .profile .profile_figcaption .name_ja {
    font-size: 1.125rem;
  }
}
.lecturer .modal_panel .comment {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9.375rem 1.25rem 3.125rem;
}
@media screen and (min-width: 769px) {
  .lecturer .modal_panel .comment {
    padding: 3.125rem 1.25rem;
  }
}
.lecturer .modal_panel .comment .comment_inner {
  max-width: 48.625rem;
  margin: 0 auto;
  flex: 1;
}
.lecturer .modal_panel .comment .sec_ja {
  font-weight: 500;
  padding-bottom: 3.125rem;
}
.lecturer .modal_panel .comment .sec_en {
  font-weight: 400;
  padding-top: 3.125rem;
}
.lecturer .modal_panel .comment .sec_ja + .sec_en {
  border-top: solid 0.0625rem #FF0600;
}
.lecturer .modal_panel .comment .capabilities {
  margin-top: 2.5rem;
}
.lecturer .modal_close {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 62.4375rem;
  border: solid 0.125rem #FF0600;
  position: absolute;
  top: -0.9375rem;
  right: -0.9375rem;
  width: 3.125rem;
  height: 3.125rem;
  width: 50px;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
}
.lecturer .modal_close img {
  width: 1.5625rem;
  height: 1.5625rem;
}
.lecturer .comment_others .comment_others_item + .comment_others_item {
  margin-top: 1.875rem;
}
@media screen and (min-width: 769px) {
  .lecturer .comment_others .comment_others_item + .comment_others_item {
    margin-top: 3.125rem;
  }
}
.lecturer .comment_others .comment_others_item img {
  width: auto !important;
  height: auto !important;
}

.pricelist .price_title {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem 1.375rem;
  align-items: center;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid #FF0600;
}
@media screen and (min-width: 769px) {
  .pricelist .price_title {
    padding-bottom: 1.875rem;
  }
}
.pricelist .price_title .strong_text {
  display: inline-block;
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 1.5;
}
@media screen and (min-width: 769px) {
  .pricelist .price_title .strong_text {
    font-size: 2.125rem;
  }
}
.pricelist .price_title .comments {
  display: inline-block;
  font-size: 0.875rem;
  color: #DF0510;
  line-height: 1.5;
  padding: 0.1875rem 0.5rem;
  border: 0.0625rem solid #FF0600;
  border-radius: 0.3125rem;
}
.pricelist .anchor_box {
  padding: 2.5rem 1.25rem;
  background-color: #F7F5F5;
  margin: 1.875rem calc(50% - 50vw) 0;
}
@media screen and (min-width: 769px) {
  .pricelist .anchor_box {
    padding: 3.75rem 2.5rem;
    margin: 3.75rem auto 0;
    border-radius: 0.625rem;
  }
}
.pricelist .anchor_box .button_lv1 {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  height: 100%;
}
@media screen and (min-width: 769px) {
  .pricelist .anchor_box .button_lv1 {
    padding: 1.125rem 1.5625rem;
  }
}
.pricelist .anchor_box .button_lv1 .button_lv1__text {
  font-size: 1rem;
}
@media screen and (min-width: 769px) {
  .pricelist .anchor_box .button_lv1 .button_lv1__text {
    font-size: 1.125rem;
  }
}
.pricelist .anchor_list {
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 1rem;
}
@media screen and (min-width: 769px) {
  .pricelist .anchor_list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.875rem;
  }
}
@media screen and (min-width: 769px) {
  .pricelist .anchor_item {
    width: 100%;
    max-width: 18.75rem;
  }
}
.pricelist .price_content {
  padding: 3.75rem 0;
  border-top: 0.0625rem solid #FF0600;
}
@media screen and (min-width: 769px) {
  .pricelist .price_content {
    padding: 7.5rem 0;
  }
}
.pricelist .price_content:first-of-type {
  border-top: none;
}
.pricelist .course_title {
  display: grid;
  gap: 0.625rem 1.875rem;
  align-items: end;
}
@media screen and (min-width: 769px) {
  .pricelist .course_title {
    display: flex;
    flex-wrap: wrap;
  }
}
.pricelist .course_title .strong_text {
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.5;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 769px) {
  .pricelist .course_title .strong_text {
    font-size: 2.375rem;
  }
}
.pricelist .course_title .other_box {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
}
@media screen and (min-width: 769px) {
  .pricelist .course_title .other_box {
    padding-bottom: 0.4375rem;
  }
}
.pricelist .course_title .other {
  font-size: 0.875rem;
  color: #ffffff;
  line-height: 1.5;
  padding: 0.1875rem 0.5rem;
  background-color: #003CF2;
  border-radius: 0.3125rem;
}
.pricelist .course_comments {
  font-size: 1rem;
  padding-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .pricelist .course_comments {
    padding-top: 1.25rem;
  }
}
.pricelist .certification_teacher {
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: 0.03em;
  padding-top: 3.125rem;
}
.pricelist .certification_teacher:first-of-type {
  padding-top: 1.875rem;
}

.pricelist .price_table {
  width: 100%;
  margin-top: 2.5rem;
  font-weight: bold;
  font-size: 1.125rem;
  text-align: center;
  line-height: 1.5;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 0.625rem;
}
.pricelist .price_table .price_thead th {
  color: #ffffff;
  font-weight: 500;
  padding: 1.0625rem 0.625rem;
  background-color: #FF0600;
  border-left: 0.0625rem solid #ffffff;
}
@media screen and (min-width: 769px) {
  .pricelist .price_table .price_thead th {
    padding: 1.0625rem;
  }
}
.pricelist .price_table .price_thead th:first-of-type {
  border-top-left-radius: 0.625rem;
  border-left: 0;
}
.pricelist .price_table .price_thead th:last-of-type {
  border-top-right-radius: 0.625rem;
}
.pricelist .price_table .price_tbody th {
  color: #ffffff;
  vertical-align: middle;
  padding: 1.0625rem 0.625rem;
  background-color: #FF0600;
  border-top: 0.0625rem solid #ffffff;
}
@media screen and (min-width: 769px) {
  .pricelist .price_table .price_tbody th {
    padding: 1.0625rem;
  }
}
.pricelist .price_table .price_tbody .brown th {
  font-weight: 500;
  background-color: #A2272C;
  border-radius: 0;
  border: none;
  padding: 0.4375rem 1.0625rem;
}
.pricelist .price_table .price_tbody td {
  vertical-align: middle;
  padding: 1.0625rem 0.625rem;
  border-left: 0.0625rem solid #CECECE;
  border-bottom: 0.0625rem solid #CECECE;
}
.pricelist .price_table .price_tbody td:last-of-type {
  border-right: 0.0625rem solid #CECECE;
}
@media screen and (min-width: 769px) {
  .pricelist .price_table .price_tbody td {
    padding: 1.0625rem;
  }
}
.pricelist .price_table .price_tbody .annotation td {
  font-weight: normal;
  font-size: 0.875rem;
  text-align: left;
  line-height: 2;
  padding: 0.9375rem 0.5625rem;
}
@media screen and (min-width: 769px) {
  .pricelist .price_table .price_tbody .annotation td {
    padding: 1.25rem 6.25rem;
  }
}
.pricelist .price_table .price_tbody .count {
  display: block;
  width: 100%;
  min-width: 4.625rem;
  line-height: 2;
  margin: 0 auto;
}
.pricelist .price_table .price_tbody .price {
  display: block;
  width: 100%;
  max-width: 9.25rem;
  margin: 0 auto;
  text-align: right;
  white-space: nowrap;
}
@media screen and (min-width: 769px) {
  .pricelist .price_table .price_tbody .price {
    max-width: 5rem;
  }
}
.pricelist .price_table .price_tbody .price_other {
  display: block;
  max-width: 7.875rem;
  margin: 0 auto;
  text-align: left;
}
.pricelist .price_table .price_tbody .online {
  position: relative;
  display: inline-block;
  text-align: left;
  padding-left: 1.875rem;
}
@media screen and (min-width: 769px) {
  .pricelist .price_table .price_tbody .online {
    padding-left: 2rem;
  }
}
.pricelist .price_table .price_tbody .online::before {
  content: "";
  position: absolute;
  top: 0.8em;
  left: 0;
  transform: translateY(-50%);
  width: 1.3125rem;
  height: 1.25rem;
  background-image: url("../svg/icons/onlinePC_white.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (min-width: 769px) {
  .pricelist .price_table .price_tbody .online::before {
    background-image: url("../svg/icons/onlinePC_red.svg");
  }
}
.pricelist .price_table .price_tbody .online > span {
  display: inline-block;
}
.pricelist .price_table.certification {
  margin-top: 0.625rem;
}
@media screen and (min-width: 769px) {
  .pricelist .price_table.certification {
    margin-top: 2.5rem;
  }
}
@media screen and (min-width: 769px) {
  .pricelist .price_table.certification .price {
    max-width: 6.5rem;
  }
}
.pricelist .price_table.teacher {
  margin-top: 3.125rem;
}
.pricelist .price_table .even {
  background-color: #F7F5F5;
}
.pricelist .price_table .font_regular {
  font-weight: 400 !important;
}
.pricelist .price_table .round_bl {
  border-bottom-left-radius: 0.625rem !important;
}
.pricelist .price_table .round_br {
  border-bottom-right-radius: 0.625rem !important;
}
.pricelist .price_table .border_ln {
  border-left: none !important;
}
.pricelist .price_table .border_rn {
  border-right: none !important;
}
.pricelist .price_table .border_bn {
  border-bottom: none !important;
}

.contact #mailformpro .contact_form_list dt,
.contact #mailformpro .contact_form_list dd {
  padding: 0;
  font-size: 1.125rem;
  width: 100%;
  border: none;
  float: none;
}
.contact #mailformpro .contact_form_list label,
.contact #mailformpro .contact_form_list input {
  margin: 0;
}
.contact #mailformpro .contact_form_list label {
  display: flex;
  align-items: baseline;
}
@media screen and (min-width: 769px) {
  .contact #mailformpro .contact_form_list label {
    align-items: center;
  }
}
.contact #mailformpro label.mfp_not_checked {
  padding: 0;
  border: none;
}
.contact #mailformpro .mfp_element_submit,
.contact #mailformpro .mfp_element_reset,
.contact #mailformpro .mfp_element_button,
.contact #mailformpro button.mfp_next,
.contact #mailformpro button.mfp_prev {
  border-radius: 0;
  padding: 0;
  border: none;
  background: none;
  text-shadow: none;
  font-size: 18px;
  cursor: pointer;
  outline: none;
}
.contact #mailformpro label.mfp_checked {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.contact #mfp_confirm_table {
  margin-top: 4.75rem;
}
@media screen and (min-width: 769px) {
  .contact #mfp_confirm_table {
    margin-top: 8.4375rem;
  }
}
.contact #mfp_confirm_table tbody {
  display: grid;
  gap: 1.25rem;
}
@media screen and (min-width: 769px) {
  .contact #mfp_confirm_table tbody {
    gap: 2.5rem;
  }
}
.contact #mfp_confirm_table .mfp_achroma,
.contact #mfp_confirm_table .mfp_colored {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.625rem;
  background: #ffffff;
}
@media screen and (min-width: 769px) {
  .contact #mfp_confirm_table .mfp_achroma,
  .contact #mfp_confirm_table .mfp_colored {
    gap: 2.5rem;
    grid-template-columns: 16.25rem 1fr;
  }
}
.contact #mfp_confirm_table .mfp_achroma th,
.contact #mfp_confirm_table .mfp_achroma td,
.contact #mfp_confirm_table .mfp_colored th,
.contact #mfp_confirm_table .mfp_colored td {
  border: none;
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
}
@media screen and (min-width: 769px) {
  .contact #mfp_confirm_table .mfp_achroma th,
  .contact #mfp_confirm_table .mfp_achroma td,
  .contact #mfp_confirm_table .mfp_colored th,
  .contact #mfp_confirm_table .mfp_colored td {
    font-size: 1.125rem;
  }
}
.contact .contact_form {
  max-width: 50rem;
  margin: 0 auto;
}
.contact .contact_form_flow {
  background: #F7F5F5;
  padding: 1.875rem 0 1.4375rem;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding-left: calc(50vw - 50%);
  padding-right: calc(50vw - 50%);
}
@media screen and (min-width: 769px) {
  .contact .contact_form_flow {
    padding: 3.125rem 0 2.5rem;
  }
}
.contact .contact_form_flow .contact_form_flow_img {
  width: 100%;
  max-width: 19.75rem;
  aspect-ratio: 5.0967741935;
  color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  .contact .contact_form_flow .contact_form_flow_img {
    max-width: 50rem;
    aspect-ratio: 12.3076923077;
  }
}
.contact .contact_form_flow .contact_form_flow_img.inputSec {
  background-image: url(../img/contact/flow/flow01_sp.png);
}
@media screen and (min-width: 769px) {
  .contact .contact_form_flow .contact_form_flow_img.inputSec {
    background-image: url(../img/contact/flow/flow01.png);
  }
}
.contact .contact_form_flow .contact_form_flow_img.confirmSec {
  background-image: url(../img/contact/flow/flow02_sp.png);
}
@media screen and (min-width: 769px) {
  .contact .contact_form_flow .contact_form_flow_img.confirmSec {
    background-image: url(../img/contact/flow/flow02.png);
  }
}
.contact .contact_form_flow .contact_form_flow_img.thanksSec {
  background-image: url(../img/contact/flow/flow03_sp.png);
}
@media screen and (min-width: 769px) {
  .contact .contact_form_flow .contact_form_flow_img.thanksSec {
    background-image: url(../img/contact/flow/flow03.png);
  }
}
.contact #mfp_phase_confirm .mfp_bug_hack,
.contact #mailformpro .mfp_bug_hack {
  display: none;
}
.contact #mfp_phase_confirm input[type=text],
.contact #mfp_phase_confirm input[type=tel],
.contact #mfp_phase_confirm input[type=email],
.contact #mfp_phase_confirm input[type=date],
.contact #mfp_phase_confirm textarea,
.contact #mfp_phase_confirm select,
.contact #mailformpro input[type=text],
.contact #mailformpro input[type=tel],
.contact #mailformpro input[type=email],
.contact #mailformpro input[type=date],
.contact #mailformpro textarea,
.contact #mailformpro select {
  width: 100%;
  max-width: 24.375rem;
  background-color: #F7F5F5;
  border-radius: 0.625rem;
  min-height: 3.75rem;
  padding: 0 1em;
  font-size: 1.125rem;
  border: 0.0625rem solid #CECECE;
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm input[type=text],
  .contact #mfp_phase_confirm input[type=tel],
  .contact #mfp_phase_confirm input[type=email],
  .contact #mfp_phase_confirm input[type=date],
  .contact #mfp_phase_confirm textarea,
  .contact #mfp_phase_confirm select,
  .contact #mailformpro input[type=text],
  .contact #mailformpro input[type=tel],
  .contact #mailformpro input[type=email],
  .contact #mailformpro input[type=date],
  .contact #mailformpro textarea,
  .contact #mailformpro select {
    max-width: 100%;
  }
}
.contact #mfp_phase_confirm textarea,
.contact #mailformpro textarea {
  padding: 1em;
}
.contact #mfp_phase_confirm input[type=checkbox],
.contact #mailformpro input[type=checkbox] {
  border-radius: 0;
  appearance: none;
  position: relative;
  width: 1.3em;
  height: 1.3em;
  border: 0.0625rem solid #FF0600;
  border-radius: 0.3125rem;
  margin-right: 0.625rem;
}
.contact #mfp_phase_confirm input[type=checkbox]:checked:before,
.contact #mailformpro input[type=checkbox]:checked:before {
  position: absolute;
  top: -0.125rem;
  left: -0.125rem;
  font-family: "Material Icons";
  content: "\e5ca";
  color: #FF0600;
  font-size: 1.4em;
}
.contact #mfp_phase_confirm input[type=radio],
.contact #mailformpro input[type=radio] {
  opacity: 0;
  position: absolute;
}
.contact #mfp_phase_confirm input[type=date],
.contact #mailformpro input[type=date] {
  position: relative;
}
.contact #mfp_phase_confirm input[type=date]::-webkit-calendar-picker-indicator,
.contact #mailformpro input[type=date]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  background: transparent;
  z-index: 1;
}
.contact #mfp_phase_confirm input[type=date]::after,
.contact #mailformpro input[type=date]::after {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  content: "";
  background-image: url(../img/contact/button_calendar.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
  width: 3.625rem;
  height: 3.625rem;
}
.contact #mfp_phase_confirm .contact_form_list,
.contact #mailformpro .contact_form_list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3.125rem;
  margin-top: 4.75rem;
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm .contact_form_list,
  .contact #mailformpro .contact_form_list {
    margin-top: 8.4375rem;
  }
}
.contact #mfp_phase_confirm .contact_form_item,
.contact #mailformpro .contact_form_item {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  align-items: flex-start;
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm .contact_form_item,
  .contact #mailformpro .contact_form_item {
    grid-template-columns: 15rem 33.125rem;
    gap: 1.875rem;
  }
}
.contact #mfp_phase_confirm .contact_form_item .contact_form_title,
.contact #mailformpro .contact_form_item .contact_form_title {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 1rem;
}
.contact #mfp_phase_confirm .contact_form_item .contact_form_title._sub,
.contact #mailformpro .contact_form_item .contact_form_title._sub {
  text-align: left;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 0;
}
.contact #mfp_phase_confirm .contact_form_item .contact_form_title._noRequire,
.contact #mailformpro .contact_form_item .contact_form_title._noRequire {
  text-align: left;
  grid-template-columns: 1fr;
}
.contact #mfp_phase_confirm .contact_form_item .contact_form_title_note,
.contact #mailformpro .contact_form_item .contact_form_title_note {
  font-size: 0.875rem;
  font-weight: 500;
}
.contact #mfp_phase_confirm .contact_form_item .select_options,
.contact #mailformpro .contact_form_item .select_options {
  position: relative;
  max-width: 25rem;
}
.contact #mfp_phase_confirm .contact_form_item .select_options > select,
.contact #mailformpro .contact_form_item .select_options > select {
  background-image: url("../img/contact/button_select.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
}
.contact #mfp_phase_confirm .contact_form_item .contact_form_radio,
.contact #mailformpro .contact_form_item .contact_form_radio {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm .contact_form_item .contact_form_radio,
  .contact #mailformpro .contact_form_item .contact_form_radio {
    gap: 0.625rem 5.625rem;
  }
}
.contact #mfp_phase_confirm .contact_form_item .contact_form_radio + .contact_form_radio,
.contact #mailformpro .contact_form_item .contact_form_radio + .contact_form_radio {
  margin-top: 0.625rem;
}
.contact #mfp_phase_confirm .contact_form_item .contact_form_radio label,
.contact #mailformpro .contact_form_item .contact_form_radio label {
  position: relative;
  padding-left: 1.5em;
}
.contact #mfp_phase_confirm .contact_form_item .contact_form_radio label::before, .contact #mfp_phase_confirm .contact_form_item .contact_form_radio label::after,
.contact #mailformpro .contact_form_item .contact_form_radio label::before,
.contact #mailformpro .contact_form_item .contact_form_radio label::after {
  position: absolute;
  top: 50%;
  display: block;
  content: "";
  width: 0.6em;
  height: 0.6em;
  border-radius: 100%;
}
.contact #mfp_phase_confirm .contact_form_item .contact_form_radio label::before,
.contact #mailformpro .contact_form_item .contact_form_radio label::before {
  width: 1em;
  height: 1em;
  border: 0.0625rem solid #FF0600;
  left: 0;
  transform: translate(0, -50%);
}
.contact #mfp_phase_confirm .contact_form_item .contact_form_radio input[type=radio]:checked + label::after,
.contact #mailformpro .contact_form_item .contact_form_radio input[type=radio]:checked + label::after {
  background: #FF0600;
  left: 0.2em;
  transform: translate(0, -50%);
}
.contact #mfp_phase_confirm .contact_form_item .require,
.contact #mailformpro .contact_form_item .require {
  font-size: 0.875rem;
  font-weight: 500;
  color: #FF0600;
  border: 0.0625rem solid #FF0600;
  border-radius: 0.3125rem;
  width: 2.8125rem;
  height: 1.6875rem;
  text-align: center;
}
.contact #mfp_phase_confirm .contact_form_item.flex_col,
.contact #mailformpro .contact_form_item.flex_col {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm .contact_form_item.flex_col .contact_form_data,
  .contact #mailformpro .contact_form_item.flex_col .contact_form_data {
    padding-left: 3.75rem;
  }
}
.contact #mfp_phase_confirm .contact_form_item.commuting_to_school ol, .contact #mfp_phase_confirm .contact_form_item.trial_lesson_time ol, .contact #mfp_phase_confirm .contact_form_item.learning_objectives ol, .contact #mfp_phase_confirm .contact_form_item.contact_hours ol,
.contact #mailformpro .contact_form_item.commuting_to_school ol,
.contact #mailformpro .contact_form_item.trial_lesson_time ol,
.contact #mailformpro .contact_form_item.learning_objectives ol,
.contact #mailformpro .contact_form_item.contact_hours ol {
  display: grid;
  gap: 0.625rem 0;
}
@media screen and (max-width: 768px) {
  .contact #mfp_phase_confirm .contact_form_item.commuting_to_school ol, .contact #mfp_phase_confirm .contact_form_item.trial_lesson_time ol, .contact #mfp_phase_confirm .contact_form_item.learning_objectives ol, .contact #mfp_phase_confirm .contact_form_item.contact_hours ol,
  .contact #mailformpro .contact_form_item.commuting_to_school ol,
  .contact #mailformpro .contact_form_item.trial_lesson_time ol,
  .contact #mailformpro .contact_form_item.learning_objectives ol,
  .contact #mailformpro .contact_form_item.contact_hours ol {
    grid-template-columns: repeat(2, 1fr);
  }
}
.contact #mfp_phase_confirm .contact_form_item.contact_hours ol,
.contact #mailformpro .contact_form_item.contact_hours ol {
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: "areaA areaB" "areaC areaD" "areaE areaE";
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm .contact_form_item.contact_hours ol,
  .contact #mailformpro .contact_form_item.contact_hours ol {
    grid-template-areas: "areaA areaB areaC areaD" "areaE areaE areaE areaE";
  }
}
.contact #mfp_phase_confirm .contact_form_item.contact_hours ol li:nth-child(1),
.contact #mailformpro .contact_form_item.contact_hours ol li:nth-child(1) {
  grid-area: areaA;
}
.contact #mfp_phase_confirm .contact_form_item.contact_hours ol li:nth-child(2),
.contact #mailformpro .contact_form_item.contact_hours ol li:nth-child(2) {
  grid-area: areaB;
}
.contact #mfp_phase_confirm .contact_form_item.contact_hours ol li:nth-child(3),
.contact #mailformpro .contact_form_item.contact_hours ol li:nth-child(3) {
  grid-area: areaC;
}
.contact #mfp_phase_confirm .contact_form_item.contact_hours ol li:nth-child(4),
.contact #mailformpro .contact_form_item.contact_hours ol li:nth-child(4) {
  grid-area: areaD;
}
.contact #mfp_phase_confirm .contact_form_item.contact_hours ol li:nth-child(5),
.contact #mailformpro .contact_form_item.contact_hours ol li:nth-child(5) {
  grid-area: areaE;
}
.contact #mfp_phase_confirm .contact_form_item.trial_lesson_time ol, .contact #mfp_phase_confirm .contact_form_item.learning_objectives ol,
.contact #mailformpro .contact_form_item.trial_lesson_time ol,
.contact #mailformpro .contact_form_item.learning_objectives ol {
  grid-template-columns: repeat(4, 1fr);
}
.contact #mfp_phase_confirm .contact_form_item .trial_lesson_date_grid,
.contact #mailformpro .contact_form_item .trial_lesson_date_grid {
  display: grid;
  gap: 1.25rem;
  align-items: center;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm .contact_form_item .trial_lesson_date_grid,
  .contact #mailformpro .contact_form_item .trial_lesson_date_grid {
    grid-template-columns: auto 25rem;
  }
}
.contact #mfp_phase_confirm .contact_form_item .trial_lesson_date_grid input[type=date],
.contact #mailformpro .contact_form_item .trial_lesson_date_grid input[type=date] {
  appearance: none;
  text-align: left;
}
.contact #mfp_phase_confirm .contact_form_item.commuting_to_school ol,
.contact #mailformpro .contact_form_item.commuting_to_school ol {
  grid-template-columns: repeat(6, 3.125rem);
  gap: 0.625rem 2.5rem;
}
.contact #mfp_phase_confirm .contact_form_item .qualification_form,
.contact #mailformpro .contact_form_item .qualification_form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
}
.contact #mfp_phase_confirm .contact_form_item .qualification_form .qualification_form_group,
.contact #mailformpro .contact_form_item .qualification_form .qualification_form_group {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6875rem;
  align-items: center;
}
.contact #mfp_phase_confirm .contact_form_item .qualification_form .qualification_form_label,
.contact #mailformpro .contact_form_item .qualification_form .qualification_form_label {
  display: grid;
  grid-template-columns: auto 5.625rem auto;
  gap: 0.9375rem;
  align-items: flex-end;
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm .contact_form_item .qualification_form .qualification_form_label,
  .contact #mailformpro .contact_form_item .qualification_form .qualification_form_label {
    align-items: center;
  }
}
.contact #mfp_phase_confirm .contact_form_item .qualification_form .qualification_form_label._other,
.contact #mailformpro .contact_form_item .qualification_form .qualification_form_label._other {
  grid-template-columns: 3.75rem 1fr;
}
.contact #mfp_phase_confirm .contact_form_item .qualification_form .qualification_form_label + .qualification_form_label,
.contact #mailformpro .contact_form_item .qualification_form .qualification_form_label + .qualification_form_label {
  position: relative;
  padding-left: 1.6875rem;
}
.contact #mfp_phase_confirm .contact_form_item .qualification_form .qualification_form_label + .qualification_form_label::before,
.contact #mailformpro .contact_form_item .qualification_form .qualification_form_label + .qualification_form_label::before {
  display: block;
  content: "/";
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(0);
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm .contact_form_item .qualification_form .qualification_form_label + .qualification_form_label::before,
  .contact #mailformpro .contact_form_item .qualification_form .qualification_form_label + .qualification_form_label::before {
    top: 50%;
    transform: translateY(-50%);
  }
}
.contact #mfp_phase_confirm .contact_form_confirm,
.contact #mailformpro .contact_form_confirm {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm .contact_form_confirm,
  .contact #mailformpro .contact_form_confirm {
    margin-top: 5rem;
  }
}
.contact #mfp_phase_confirm .contact_form_confirm_buttons,
.contact #mailformpro .contact_form_confirm_buttons {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.625rem 2.5rem;
  width: 100%;
  max-width: 50rem;
  margin-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm .contact_form_confirm_buttons,
  .contact #mailformpro .contact_form_confirm_buttons {
    margin-top: 7.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
}
.contact #mfp_phase_confirm .contact_form_back_button,
.contact #mfp_phase_confirm .contact_form_submit_button,
.contact #mfp_phase_confirm .contact_form_confirm_button,
.contact #mailformpro .contact_form_back_button,
.contact #mailformpro .contact_form_submit_button,
.contact #mailformpro .contact_form_confirm_button {
  position: relative;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: transparent;
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm .contact_form_back_button,
  .contact #mfp_phase_confirm .contact_form_submit_button,
  .contact #mfp_phase_confirm .contact_form_confirm_button,
  .contact #mailformpro .contact_form_back_button,
  .contact #mailformpro .contact_form_submit_button,
  .contact #mailformpro .contact_form_confirm_button {
    margin: 0;
  }
}
.contact #mfp_phase_confirm .contact_form_confirm_button,
.contact #mailformpro .contact_form_confirm_button {
  max-width: 21rem;
  aspect-ratio: 5.6;
  background-image: url(../img/contact/button_confirm_sp@2x.png);
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm .contact_form_confirm_button,
  .contact #mailformpro .contact_form_confirm_button {
    max-width: 31.25rem;
    aspect-ratio: 6.6666666667;
    background-image: url(../img/contact/button_confirm@2x.png);
  }
}
.contact #mfp_phase_confirm .contact_form_back_button,
.contact #mailformpro .contact_form_back_button {
  max-width: 21rem;
  aspect-ratio: 5.6;
  background-image: url(../img/contact/button_back_sp@2x.png);
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm .contact_form_back_button,
  .contact #mailformpro .contact_form_back_button {
    max-width: 31.25rem;
    aspect-ratio: 6.6666666667;
    background-image: url(../img/contact/button_back@2x.png);
  }
}
.contact #mfp_phase_confirm .contact_form_submit_button,
.contact #mailformpro .contact_form_submit_button {
  max-width: 21rem;
  aspect-ratio: 5.6;
  background-image: url(../img/contact/button_submit_sp@2x.png);
}
@media screen and (min-width: 769px) {
  .contact #mfp_phase_confirm .contact_form_submit_button,
  .contact #mailformpro .contact_form_submit_button {
    max-width: 31.25rem;
    aspect-ratio: 6.6666666667;
    background-image: url(../img/contact/button_submit@2x.png);
  }
}

.thanks .contact_thanks_text_wrapper {
  max-width: 50rem;
  margin: 0 auto;
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .thanks .contact_thanks_text_wrapper {
    margin-top: 5rem;
  }
}
.thanks .contact_thanks_text {
  font-size: 1rem;
  font-weight: 700;
  line-height: 2;
}
@media screen and (min-width: 769px) {
  .thanks .contact_thanks_text {
    font-size: 1.125rem;
  }
}
.thanks .contact_thanks_text._red {
  color: #FF0600;
}
.thanks .contact_thanks_text + .contact_thanks_text {
  margin-top: 2rem;
}
.thanks .contact_thanks_button {
  margin: 0 auto;
  max-width: 23.75rem;
  margin-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .thanks .contact_thanks_button {
    max-width: 31.25rem;
    margin-top: 5rem;
  }
}

.online .course_container {
  max-width: 56.25rem;
}
.online .online_content {
  background-color: #FF0600;
  padding: 1.875rem 0;
}
@media screen and (min-width: 769px) {
  .online .online_content {
    padding: 3.75rem 0 8.125rem;
  }
}
.online .online_container {
  width: 100%;
  max-width: 72.5rem;
  margin: 0 auto;
}
.online .online_chatch {
  font-weight: 800;
  font-size: 1.25rem;
  color: #ffffff;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 769px) {
  .online .online_chatch {
    font-size: 1.875rem;
  }
}
.online .online_chatch .large {
  font-size: 2.125rem;
}
@media screen and (min-width: 769px) {
  .online .online_chatch .large {
    font-size: 3.125rem;
  }
}
.online .chatch_bottom {
  font-weight: 800;
  font-size: 1.25rem;
  color: #ffffff;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.03em;
  padding-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .online .chatch_bottom {
    padding-top: 0;
  }
}
.online .chatch_bottom .large {
  font-size: 2.125rem;
}
@media screen and (min-width: 769px) {
  .online .chatch_bottom .large {
    font-size: 3.125rem;
  }
}
.online .chatch_bottom.first {
  padding-top: 2.5rem;
}
.online .online_list {
  display: grid;
  gap: 1.25rem;
  padding-top: 2.5rem;
}
@media screen and (min-width: 769px) {
  .online .online_list {
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
  }
}
.online .online_item {
  padding: 1.875rem;
  background-color: #ffffff;
  border-radius: 0.625rem;
}
.online .point_number {
  font-weight: bold;
  font-size: 1rem;
  color: #FF0600;
}
@media screen and (min-width: 769px) {
  .online .point_number {
    font-size: 1.125rem;
  }
}
.online .point_title {
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.5;
  letter-spacing: 0.03em;
  padding-top: 0.625rem;
}
@media screen and (min-width: 769px) {
  .online .point_title {
    font-size: 1.875rem;
    padding-top: 0.9375rem;
  }
}
.online .point_title .red {
  color: #FF0600;
}
.online .point_inner {
  display: grid;
  gap: 1.25rem;
  padding-top: 1.25rem;
}
@media screen and (min-width: 769px) {
  .online .point_inner {
    display: flex;
    align-items: start;
    gap: 1.5rem;
    padding-top: 1.875rem;
  }
}
.online .point_text {
  order: 2;
  width: 100%;
  font-size: 1rem;
}
@media screen and (min-width: 769px) {
  .online .point_text {
    order: 1;
  }
}
.online .point_text .small {
  font-size: 0.75rem;
}
.online .point_img {
  order: 1;
  width: 100%;
  max-width: 12.5rem;
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  .online .point_img {
    order: 2;
    margin: 0;
  }
}
.online .classroom_box {
  background-color: #F7F5F5;
  padding: 3.125rem 0;
  margin-top: 3.75rem;
}
@media screen and (min-width: 769px) {
  .online .classroom_box {
    padding: 3.75rem 0;
    margin-top: 7.5rem;
  }
}
.online .classroom_list {
  display: grid;
  gap: 2.5rem;
}
@media screen and (min-width: 769px) {
  .online .classroom_list {
    grid-template-columns: repeat(2, 1fr);
    gap: 3.75rem;
  }
}
.online .classroom_list .button_lv1 {
  padding: 0.625rem 0.9375rem;
}
@media screen and (min-width: 769px) {
  .online .classroom_list .button_lv1 {
    padding: 0.625rem 1.5625rem;
  }
}
.online .classroom_item .map {
  width: 100%;
  aspect-ratio: 1.5272727273;
  line-height: 1;
}
@media screen and (min-width: 769px) {
  .online .classroom_item .map {
    aspect-ratio: 2.1363636364;
  }
}
.online .classroom_item .button {
  padding-top: 1.875rem;
}
.online .classroom_item .address {
  font-size: 1rem;
  font-weight: bold;
  padding-top: 1.25rem;
  padding-left: 0.625rem;
}
@media screen and (min-width: 769px) {
  .online .classroom_item .address {
    font-size: 1.125rem;
    padding-left: 1.5625rem;
  }
}