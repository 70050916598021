@use '../../../base/vars'as *;
@use 'sass:math';

.ly_belt{
    background-color: $red;
    color: $white;
    font-weight: 900;
    line-height: 1.4;
    text-align: center;
    margin:  0 calc(50% - 50vw);
    padding: rem(27) calc(50vw - 50%);
    font-feature-settings: "palt";

    @include media(sm){
        padding: rem(60) calc(50vw - 50%);
    }

    &,
    .ly_belt_lead{
        line-height: 1.6;
        letter-spacing: 0.05em;
        font-size: rem(20);
        letter-spacing: 0.03em;

        @include media(sm){
            font-size: rem(30);
        }
    }

    .ly_belt-strong{
        display: inline-block;
        font-size: rem(34);

        @include media(sm){
            font-size: rem(50);
        }
    }

    .ly_belt_lists{
        margin: rem(40) auto 0;
        max-width: rem(770);
    }

    .ly_belt_lists-col2{
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(20);

        @include media(sm){
            grid-template-columns: repeat(2, 1fr);
            gap: rem(30);
        }
    }
}
