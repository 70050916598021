@use '../../../base/vars'as *;
@use 'sass:math';

.pricelist{
    .price_title{
        display: flex;
        flex-wrap: wrap;
        gap: rem(10) rem(22);
        align-items: center;
        padding-bottom: rem(20);
        border-bottom: rem(1) solid $red;

        @include media(sm){
            padding-bottom: rem(30);
        }

        .strong_text{
            display: inline-block;
            font-weight: bold;
            font-size: rem(28);
            line-height: 1.5;

            @include media(sm){
                font-size: rem(34);
            }
        }

        .comments{
            display: inline-block;
            font-size: rem(14);
            color: $red_lv3;
            line-height: 1.5;
            padding: rem(3) rem(8);
            border: rem(1) solid $red;
            border-radius: rem(5);
        }
    }

    .anchor_box{
        padding: rem(40) rem(20);
        background-color: $gray_lv2;
        margin: rem(30) calc(50% - 50vw) 0;

        @include media(sm){
            padding: rem(60) rem(40);
            margin: rem(60) auto 0;
            border-radius: rem(10);
        }

        .button_lv1{
            display: flex;
            align-items: center;
            padding: rem(12);
            height: 100%;

            @include media(sm) {
                padding: rem(18) rem(25);
            }

            .button_lv1__text{
                font-size: rem(16);
    
                @include media(sm){
                    font-size: rem(18);
                }
            }
        }
    }

    .anchor_list{
        width: 100%;
        max-width: rem(960);
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        gap: rem(16);

        @include media(sm){
            display: flex;
            flex-wrap: wrap;
            gap: rem(30);
        }
    }

    .anchor_item{
        @include media(sm){
            width: 100%;
            max-width: rem(300);
        }
    }

    .price_content{
        padding: rem(60) 0;
        border-top: rem(1) solid $red;

        @include media(sm){
            padding: rem(120) 0; 
        }

        &:first-of-type{
            border-top: none;
        }
    }

    .course_title{
        display: grid;
        gap: rem(10) rem(30);
        align-items: end;

        @include media(sm){
            display: flex;
            flex-wrap: wrap;
        }

        .strong_text{
            font-weight: bold;
            font-size: rem(32);
            line-height: 1.5;
            letter-spacing: 0.03em;

            @include media(sm){
                font-size: rem(38);
            }
        }

        .other_box{
            display: flex;
            flex-wrap: wrap;
            gap: rem(10);

            @include media(sm){
                padding-bottom: rem(7);
            }
        }

        .other{
            font-size: rem(14);
            color: $white;
            line-height: 1.5;
            padding: rem(3) rem(8);
            background-color: $blue_lv2;
            border-radius: rem(5);
        }
    }

    .course_comments{
        font-size: rem(16);
        padding-top: rem(40);

        @include media(sm){
            padding-top: rem(20);
        }
    }

    .certification_teacher{
        font-weight: bold;
        font-size: rem(20);
        letter-spacing: 0.03em;
        padding-top: rem(50);

        &:first-of-type{
            padding-top: rem(30);
        }
    }
}
