@use '../../base/vars'as *;
@use 'sass:math';

.button_lv1 {
    text-align: center;
    padding: rem(15);
    border-radius: rem(10);
    background-color: $blue_lv2;

    @include media(sm) {
        padding: rem(18) rem(25);
    }

    .button_lv1__text {
        position: relative;
        display: block;
        width: 100%;
        color: $white;
        font-size: rem(18);
        font-weight: 500;
        letter-spacing: 0.03em;
        line-height: 1.5;
        padding-right: rem(20);

        @include media(sm) {
            font-size: rem(20);
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: rem(17);
            height: rem(14);
            background-image: url('../svg/icons/arrowWhite_icon.svg');
            background-repeat: no-repeat;
            background-size: contain;
            transition: transform .3s ease-out;
        }

        >span {
            display: inline-block;
        }
    }

    &:hover {
        .button_lv1__text::after {
            transform: translateY(-50%) translateX(50%);
        }
    }

    &.red {
        background-color: $white;

        .button_lv1__text {
            color: $red;

            &::after {
                background-image: url('../svg/icons/arrowRed_icon.svg');
            }
        }
    }

    &.anchor {
        .button_lv1__text {
            &::after {
                transform: translateY(-50%) rotate(90deg);
            }
        }

        &:hover {
            .button_lv1__text::after {
                transform: translateY(0) rotate(90deg);
            }
        }
    }

    &.icon_insta {
        .button_lv1__text {

            &::after {
                width: rem(16);
                height: rem(16);
                background-image: url('../svg/icons/insta.svg');
                transition: none;
            }
        }

        &:hover {
            .button_lv1__text::after {
                transform: translateY(-50%);
            }
        }
    }

    &.solid-red{
        border: solid rem(2) $red;
    }
}
