@use '../../base/vars'as *;
@use 'sass:math';

// ここには基本的に書き込みは市内想定です。
// accordionの基本的な動きのみなので、
// 独自クラス名を追加して、そちらで装飾してください。
// 例としてはheaderのhamburger参照のこと

.accordion {
    .accordion_button {}

    .accordion_panel {
        height: 0;
        opacity: 0;
        visibility: hidden;

        &.is_open {
            height: auto;
            opacity: 1;
            visibility: visible;
            transition:
                line-height .3s ease-out,
                opacity .2s linear .1s,
                visibility .1s linear .1s;
        }
    }
}
