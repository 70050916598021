@use '../../../base/vars'as *;

.humburger_nav {
    display: block;

    @include media(md) {
        display: none;
    }

    .humburger_toggle {
        position: relative;
        width: rem(40);
        height: rem(22);

        &.is_expanded {
            .humburger_icon {
                background: none;

                &::before {
                    transform: translateY(rem(11)) rotate(45deg);
                }

                &::after {
                    transform: translateY(rem(-11)) rotate(-45deg);
                }
            }
        }
    }

    .humburger_icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: rem(2);
        background: $red;

        &::before,
        &::after {
            display: block;
            content: "";
            position: absolute;
            width: 100%;
            height: rem(2);
            background: $red;
            transition: transform .3s ease-in-out;
        }

        &::before {
            top: rem(-11);
        }

        &:after {
            bottom: rem(-11);
        }
    }

    .humburger_menu {
        z-index: $hamburger;
        position: absolute;
        top: rem(70);
        right: 0;
        transform: translateX(100%);
        background: $red;
        width: rem(208);
        padding: rem(40) rem(20);
        border-radius: rem(10);
        transition: transform .3s ease-in-out;
        height: 70vh;
        overflow: auto;

        @include media(sm) {
            top: rem(120);
        }

        &.is_open {
            transform: translate(0);
        }
    }

    .humburger_list {}

    .humburger_item {
        border-bottom: rem(1) solid $white;

        &:last-of-type {
            margin-top: rem(40);
            border-bottom: none;
        }
    }

    .humburger_toggle_button,
    .humburger_sublink,
    .humburger_link {
        color: $white;
        font-size: rem(14);
        font-weight: 700;
        padding: rem(20) 0;
    }

    .humburger_toggle_button {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .material-symbols-outlined {
            transition: transform .3s ease-in-out;
        }

        &.is_expanded {
            .material-symbols-outlined {
                transform: rotate(180deg);
            }
        }
    }

    .humburger_sublist {
        display: flex;
        flex-direction: column;
        gap: rem(16);
        padding-left: .7em;
        padding-bottom: rem(20);
    }

    .humburger_subitem {}

    .humburger_sublink {
        padding: 0;
    }

    .humburger_contact_button {}
}
