@use '../../../base/vars'as *;
@use 'sass:math';

.kids {
    .kids_main {
        padding: rem(32) 0 rem(80);
        @include mx-full;

        @include media(sm) {
            padding: 0 rem(20) rem(120);
        }
    }

    .kids_main_img {
        position: relative;
        width: 100%;
        max-width: rem(500);
        aspect-ratio: calc(376/628);
        margin: 0 auto;

        @include media(sm) {
            max-width: rem(1757);
            aspect-ratio: calc(1757/958);
        }
    }

    .kids_voice {
        margin-top: rem(80);
        background: $gray_lv2;

        @include mx-full;
        @include px-full;

        @include media(sm) {
            margin-top: rem(120);
        }
    }

    .kids_lesson {
        background: $gray_lv2;
        margin-top: rem(60);
        @include mx-full;

        @include media(sm) {
            margin-top: rem(120);
        }
    }

    .kids_lesson_inner {
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(80);
        padding: rem(30) rem(20);

        @include media(md) {
            grid-template-columns: repeat(2, 1fr);
            gap: vw(80);
            padding: rem(95) vw(100) rem(103);
        }

        @include media(xxl) {
            gap: rem(80);
            padding: rem(95) rem(100) rem(103);
        }

        .kids_lesson_title {
            font-size: rem(18);
            font-weight: 700;
            padding-left: rem(10);
            line-height: calc(24/18);

            @include media(md) {
                padding-left: 0;
                font-size: vw(24);
            }

            @include media(xxl) {
                font-size: rem(24);
            }

            span {
                display: block;
                color: $red_lv1;

                @include media(md) {
                    display: inline;
                }
            }
        }

        .kids_lesson_movie {
            position: relative;
            width: 100%;
            aspect-ratio: calc(623/224);
            background: $gray;
            margin-top: rem(5);
        }
    }

    .kids_course {

        .kids_course_title {
            max-width: rem(1000);
            margin: 0 auto;
        }

        .kids_course_list {
            display: grid;
            grid-template-columns: 1fr;
            gap: rem(30);
            width: 100%;
            max-width: rem(1158);
            margin: 0 auto;
            margin-top: rem(30);

            @include media(md) {
                margin-top: rem(66);
                grid-template-columns: repeat(2, 1fr);
                gap: rem(50);
            }

            .kids_course_item {
                border: rem(1) solid $red;
                border-bottom: rem(10) solid $blue;
                border-radius: rem(10);
                padding: rem(20) rem(10);

                @include media(sm) {
                    padding: rem(30) rem(30) rem(40);
                }
            }
        }

        .kids_course_card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .kids_course_card_number {
                display: flex;
                gap: rem(8);
                color: $red;
                font-size: rem(16);
                font-weight: 700;

                @include media(sm) {
                    font-size: rem(20);
                }
            }

            .kids_course_card_heading {
                font-size: rem(20);
                font-weight: 700;
                text-align: center;
                color: $white;
                background: $red;
                width: 100%;
                border-radius: rem(10);
                padding: rem(14) rem(9);
                margin-top: rem(10);
                line-height: calc(42/30);

                @include media(sm) {
                    padding: rem(16) rem(20) rem(20);
                    font-size: rem(30);
                }
            }

            .kids_course_card_img {
                width: 100%;
                max-width: rem(296);
                aspect-ratio: calc(450/260);
                position: relative;
                border-radius: rem(10);
                overflow: hidden;
                margin-top: rem(20);

                @include media(sm) {
                    max-width: rem(450);
                    margin-top: rem(40);
                }
            }

            .kids_course_card_description {
                margin: 0 auto;
                margin-top: rem(15);
                max-width: rem(296);

                @include media(sm) {
                    max-width: rem(450);
                    margin-top: rem(10);
                }

                >p {
                    font-size: rem(16);
                    font-weight: 500;
                }
            }
        }
    }

    .kids_movie {
        padding: rem(80) 0 rem(100);
        @include mx-full;
        @include px-full;


        .kids_movie_title {
            font-size: rem(18);
            font-weight: 700;

            @include media(sm) {
                font-size: rem(34);
            }
        }

        .kids_movie_inner {}

        .kids_movie_inner {
            display: grid;
            grid-template-columns: 1fr;
            gap: rem(30) rem(80);
            margin-top: rem(30);

            @include media(sm) {
                margin-top: rem(60);
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .kids_movie_movie {
            position: relative;
            width: 100%;
            aspect-ratio: calc(472/354);
            background: $gray;
        }
    }

    .kids_introduction {
        padding-top: rem(60);

        @include media(sm) {
            padding-top: rem(120);
        }

        .kids_introduction_list {
            display: grid;
            grid-template-columns: 1fr;
            gap: rem(40);

            @include media(sm) {
                gap: rem(80);
            }
        }
    }
}
