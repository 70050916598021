@use '../../../base/vars'as *;
@use 'sass:math';

.aboutSec {
    padding: rem(70) 0;

    @include media(sm) {
        padding: rem(120) 0 rem(96);
    }

    .aboutSec_inner {
        margin-top: rem(70);

        @include media(sm) {
            margin-top: rem(160);
        }
    }

    .aboutSec_inner_list {
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(40);

        @include media(sm) {
            gap: rem(80);
        }
    }
}
