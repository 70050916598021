@use '../../../base/vars'as *;
@use 'sass:math';

.contact {

    // mailformproの元CSSを上書き
    #mailformpro {
        .contact_form_list {

            dt,
            dd {
                padding: 0;
                font-size: rem(18);
                width: 100%;
                border: none;
                float: none;
            }

            label,
            input {
                margin: 0;
            }

            label {
                display: flex;
                align-items: baseline;

                @include media(sm) {
                    align-items: center;
                }
            }
        }

        label.mfp_not_checked {
            padding: 0;
            border: none;
        }

        .mfp_element_submit,
        .mfp_element_reset,
        .mfp_element_button,
        button.mfp_next,
        button.mfp_prev {
            border-radius: 0;
            padding: 0;
            border: none;
            background: none;
            text-shadow: none;
            font-size: 18px;
            cursor: pointer;
            outline: none;
        }

        label.mfp_checked {
            padding: 0;
            border: none;
            background-color: transparent;
            box-shadow: none;
        }
    }

    #mfp_confirm_table {
        margin-top: rem(76);

        @include media(sm) {
            margin-top: rem(135);
        }

        tbody {
            display: grid;
            gap: rem(20);

            @include media(sm) {
                gap: rem(40);
            }
        }

        .mfp_achroma,
        .mfp_colored {
            display: grid;
            grid-template-columns: 1fr;
            gap: rem(10);
            background: $white;

            @include media(sm) {
                gap: rem(40);
                grid-template-columns: rem(260) 1fr;
            }

            th,
            td {
                border: none;
                padding: 0;
                margin: 0;
                font-size: rem(14);
                font-weight: 400;

                @include media(sm) {
                    font-size: rem(18);
                }
            }
        }
    }

    // ここから追加
    .contact_form {
        max-width: rem(800);
        margin: 0 auto;
    }

    .contact_form_flow {
        background: $gray_lv2;
        padding: rem(30) 0 rem(23);
        @include mx-full;
        @include px-full;

        @include media(sm) {
            padding: rem(50) 0 rem(40);
        }

        .contact_form_flow_img {
            width: 100%;
            max-width: rem(316);
            aspect-ratio: calc(316/62);
            color: transparent;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin: 0 auto;

            @include media(sm) {
                max-width: rem(800);
                aspect-ratio: calc(800/65);
            }

            &.inputSec {
                background-image: url(../img/contact/flow/flow01_sp.png);

                @include media(sm) {
                    background-image: url(../img/contact/flow/flow01.png);
                }
            }

            &.confirmSec {
                background-image: url(../img/contact/flow/flow02_sp.png);

                @include media(sm) {
                    background-image: url(../img/contact/flow/flow02.png);
                }
            }

            &.thanksSec {
                background-image: url(../img/contact/flow/flow03_sp.png);

                @include media(sm) {
                    background-image: url(../img/contact/flow/flow03.png);
                }
            }
        }
    }

    #mfp_phase_confirm,
    #mailformpro {

        .mfp_bug_hack {
            display: none;
        }

        input[type="text"],
        input[type="tel"],
        input[type="email"],
        input[type="date"],
        textarea,
        select {
            width: 100%;
            max-width: rem(390);
            background-color: $gray_lv2;
            border-radius: rem(10);
            min-height: rem(60);
            padding: 0 1em;
            font-size: rem(18);
            border: rem(1) solid $gray_lv3;

            @include media(sm) {
                max-width: 100%;
            }
        }

        textarea {
            padding: 1em;
        }

        input[type="checkbox"] {
            border-radius: 0;
            appearance: none;
            position: relative;
            width: 1.3em;
            height: 1.3em;
            border: rem(1) solid $red;
            border-radius: rem(5);
            margin-right: rem(10);

            &:checked:before {
                position: absolute;
                top: rem(-2);
                left: rem(-2);
                font-family: "Material Icons";
                content: '\e5ca';
                color: $red;
                font-size: 1.4em;
            }
        }

        input[type=radio] {
            opacity: 0;
            position: absolute;
        }

        input[type="date"] {
            position: relative;

            &::-webkit-calendar-picker-indicator {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: auto;
                height: auto;
                background: transparent;
                z-index: 1;
            }

            &::after {
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                content: '';
                background-image: url(../img/contact/button_calendar.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right center;
                width: rem(58);
                height: rem(58);
            }
        }

        .contact_form_list {
            display: grid;
            grid-template-columns: 1fr;
            gap: rem(50);
            margin-top: rem(76);

            @include media(sm) {
                margin-top: rem(135);
            }
        }

        .contact_form_item {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            gap: rem(30);
            align-items: flex-start;

            @include media(sm) {
                grid-template-columns: rem(240) rem(530);
                gap: rem(30);
            }

            .contact_form_title {
                position: relative;
                display: grid;
                align-items: center;
                grid-template-columns: auto 1fr;
                gap: rem(16);

                &._sub {
                    text-align: left;
                    grid-template-columns: 1fr;
                    grid-template-rows: auto auto;
                    gap: 0;
                }

                &._noRequire {
                    text-align: left;
                    grid-template-columns: 1fr;
                }
            }

            .contact_form_title_note {
                font-size: rem(14);
                font-weight: 500;
            }

            .contact_form_data {}

            .select_options {
                position: relative;
                max-width: rem(400);

                >select {
                    background-image: url("../img/contact/button_select.png");
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: right center;
                }
            }

            .contact_form_radio {
                display: flex;
                flex-wrap: wrap;
                gap: rem(10);

                @include media(sm) {
                    gap: rem(10) rem(90);
                }

                &+.contact_form_radio {
                    margin-top: rem(10);
                }

                label {
                    position: relative;
                    padding-left: 1.5em;

                    &::before,
                    &::after {
                        position: absolute;
                        top: 50%;
                        display: block;
                        content: "";
                        width: .6em;
                        height: .6em;
                        border-radius: 100%;
                    }

                    &::before {
                        width: 1em;
                        height: 1em;
                        border: rem(1) solid $red;
                        left: 0;
                        transform: translate(0, -50%);
                    }
                }

                input[type="radio"]:checked+label {
                    &::after {
                        background: $red;
                        left: .2em;
                        transform: translate(0, -50%);
                    }
                }
            }

            .require {
                font-size: rem(14);
                font-weight: 500;
                color: $red;
                border: rem(1) solid $red;
                border-radius: rem(5);
                width: rem(45);
                height: rem(27);
                text-align: center;
            }

            &.flex_col {
                display: flex;
                flex-direction: column;

                .contact_form_data {
                    @include media(sm) {
                        padding-left: rem(60);
                    }
                }
            }

            &.commuting_to_school,
            &.trial_lesson_time,
            &.learning_objectives,
            &.contact_hours {

                ol {
                    display: grid;
                    gap: rem(10) 0;

                    @include max-media(sm) {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }

            &.contact_hours {
                ol {
                    grid-template-columns: repeat(4, 1fr);
                    grid-template-areas:
                        "areaA areaB"
                        "areaC areaD"
                        "areaE areaE";

                    @include media(sm) {
                        grid-template-areas:
                            "areaA areaB areaC areaD"
                            "areaE areaE areaE areaE";
                    }

                    li {
                        &:nth-child(1) {
                            grid-area: areaA;
                        }

                        &:nth-child(2) {
                            grid-area: areaB;
                        }

                        &:nth-child(3) {
                            grid-area: areaC;
                        }

                        &:nth-child(4) {
                            grid-area: areaD;
                        }

                        &:nth-child(5) {
                            grid-area: areaE;
                        }
                    }
                }
            }

            &.trial_lesson_time,
            &.learning_objectives {
                ol {
                    grid-template-columns: repeat(4, 1fr);
                }
            }

            .trial_lesson_date_grid {
                display: grid;
                gap: rem(20);
                align-items: center;
                grid-template-columns: 1fr;

                @include media(sm) {
                    grid-template-columns: auto rem(400);
                }

                dd {}

                input[type="date"] {
                    appearance: none;
                    text-align: left;
                }
            }

            &.commuting_to_school {
                ol {
                    grid-template-columns: repeat(6, rem(50));
                    gap: rem(10) rem(40);
                }
            }

            .qualification_form {
                display: grid;
                grid-template-columns: 1fr;
                gap: rem(20);

                .qualification_form_group {
                    display: flex;
                    flex-wrap: wrap;
                    gap: rem(27);
                    align-items: center;
                }

                .qualification_form_label {
                    display: grid;
                    grid-template-columns: auto rem(90) auto;
                    gap: rem(15);
                    align-items: flex-end;

                        @include media(sm) {
                            align-items: center;
                        }

                    &._other {
                        grid-template-columns: rem(60) 1fr;
                    }

                    &+.qualification_form_label {
                        position: relative;
                        padding-left: rem(27);

                        &::before {
                            display: block;
                            content: "/";
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            transform: translateY(0);

                            @include media(sm) {
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }

                .qualification_form_label_text {}

                .qualification_form_input {}

                .qualification_form_unit {}

                .qualification_form_separator {}
            }

        }

        .contact_form_confirm {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            margin-top: rem(60);

            @include media(sm) {
                margin-top: rem(80);
            }
        }

        .contact_form_confirm_buttons {
            display: grid;
            grid-template-columns: 1fr;
            gap: rem(10) rem(40);
            width: 100%;
            max-width: rem(800);
            margin-top: rem(60);

            @include media(sm) {
                margin-top: rem(120);
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .contact_form_back_button,
        .contact_form_submit_button,
        .contact_form_confirm_button {
            position: relative;
            width: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            color: transparent;
            margin: 0 auto;

            @include media(sm) {
                margin: 0;
            }
        }

        .contact_form_confirm_button {
            max-width: rem(336);
            aspect-ratio: calc(336/60);
            background-image: url(../img/contact/button_confirm_sp@2x.png);

            @include media(sm) {
                max-width: rem(500);
                aspect-ratio: calc(500/75);
                background-image: url(../img/contact/button_confirm@2x.png);
            }
        }

        .contact_form_back_button {
            max-width: rem(336);
            aspect-ratio: calc(336/60);
            background-image: url(../img/contact/button_back_sp@2x.png);

            @include media(sm) {
                max-width: rem(500);
                aspect-ratio: calc(500/75);
                background-image: url(../img/contact/button_back@2x.png);
            }
        }

        .contact_form_submit_button {
            max-width: rem(336);
            aspect-ratio: calc(336/60);
            background-image: url(../img/contact/button_submit_sp@2x.png);

            @include media(sm) {
                max-width: rem(500);
                aspect-ratio: calc(500/75);
                background-image: url(../img/contact/button_submit@2x.png);
            }
        }
    }
}


.thanks {
    .contact_thanks_text_wrapper {
        max-width: rem(800);
        margin: 0 auto;
        margin-top: rem(40);

        @include media(sm) {
            margin-top: rem(80);
        }
    }

    .contact_thanks_text {
        font-size: rem(16);
        font-weight: 700;
        line-height: calc(36/18);

        @include media(sm) {
            font-size: rem(18);
        }

        &._red {
            color: $red;
        }

        &+.contact_thanks_text {
            margin-top: rem(32);
        }
    }

    .contact_thanks_button {
        margin: 0 auto;
        max-width: rem(380);
        margin-top: rem(40);

        @include media(sm) {
            max-width: rem(500);
            margin-top: rem(80);
        }
    }

}
